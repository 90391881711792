import React, { useState, useEffect } from 'react';
import { Typography, Grid } from '@mui/material';
import { getTrendingNewReleases } from '../../services/api';
import GameCard from '../../components/GameCard';
import LoadingPlaceholder from '../../components/LoadingPlaceholder';
import { Game } from '../../types/Game';

const TrendingNewReleasesPage: React.FC = () => {
  const [games, setGames] = useState<Game[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchGames = async () => {
    setLoading(true);
    try {
      const response = await getTrendingNewReleases(30); // 一度に100件取得
      setGames(response.data.results);
    } catch (error) {
      console.error('ゲーム情報の取得に失敗しました', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGames();
  }, []);

  if (loading) {
    return <LoadingPlaceholder type="card" count={30} />;
  }

  return (
    <>
      <Typography variant="h4" component="h1" gutterBottom>
        話題の新作
      </Typography>
      <Grid container spacing={2}>
        {games.map((game) => (
          <Grid item xs={6} sm={4} md={2.4} key={game.game_id}>
            <GameCard game={game} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default TrendingNewReleasesPage;
