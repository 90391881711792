import React, { useState, useEffect } from 'react';
import { Container, Typography, Grid, Card, CardContent, CardActionArea } from '@mui/material';
import { Link } from 'react-router-dom';
import { getThemes } from '../../services/api';
import LoadingPlaceholder from '../../components/LoadingPlaceholder';

interface Theme {
  theme_id: number;
  name: string;
  slug: string;
}

const ThemeListPage: React.FC = () => {
  const [themes, setThemes] = useState<Theme[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchThemes = async () => {
      try {
        const data = await getThemes();
        setThemes(data);
      } catch (error) {
        console.error('テーマ一覧の取得に失敗しました', error);
      } finally {
        setLoading(false);
      }
    };

    fetchThemes();
  }, []);

  if (loading) {
    return <LoadingPlaceholder type="card" count={12} />;
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        ゲームテーマ一覧
      </Typography>
      <Grid container spacing={3}>
        {themes.map((theme) => (
          <Grid item xs={12} sm={6} md={4} key={theme.theme_id}>
            <Card>
              <CardActionArea component={Link} to={`/games/theme/${theme.theme_id}`}>
                <CardContent>
                  <Typography variant="h6" component="div">
                    {theme.name}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ThemeListPage;