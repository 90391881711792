import React, { useState } from 'react';
import { 
  Box, 
  TextField, 
  Button, 
  IconButton, 
  Card, 
  CardContent,
  Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { ContentBlock } from '../../types/content';

interface StoryEditorProps {
  blocks: ContentBlock[];
  onChange: (blocks: ContentBlock[]) => void;
}

const StoryEditor: React.FC<StoryEditorProps> = ({ blocks, onChange }) => {
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null);
  const [bulkInput, setBulkInput] = useState('');

  const addTextBlock = () => {
    onChange([...blocks, { type: 'text', content: '' }]);
  };

  const addImageBlock = () => {
    onChange([...blocks, { type: 'image', url: '', caption: '' }]);
  };

  const addHeadingBlock = () => {
    onChange([...blocks, { type: 'heading', content: '' }]);
  };

  const updateBlock = (index: number, updatedBlock: ContentBlock) => {
    const newBlocks = [...blocks];
    newBlocks[index] = updatedBlock;
    onChange(newBlocks);
  };

  const removeBlock = (index: number) => {
    onChange(blocks.filter((_, i) => i !== index));
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const items = Array.from(blocks);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    onChange(items);
  };

  const handleBulkInput = () => {
    const newBlocks: ContentBlock[] = [];
    const lines = bulkInput.split('\n').filter(line => line.trim());

    lines.forEach(line => {
      if (line.startsWith('h1:') || line.startsWith('h2:') || line.startsWith('h3:')) {
        newBlocks.push({
          type: 'heading',
          content: line.substring(3).trim()
        });
      } else if (line.startsWith('http')) {
        newBlocks.push({
          type: 'image',
          url: line.trim(),
          caption: ''
        });
      } else {
        newBlocks.push({
          type: 'text',
          content: line.trim()
        });
      }
    });

    onChange(newBlocks);
    setBulkInput('');
  };

  return (
    <Box>
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <TextField
            fullWidth
            multiline
            rows={4}
            value={bulkInput}
            onChange={(e) => setBulkInput(e.target.value)}
            placeholder="一括入力 (h1:, h2:, h3: で見出し、URLで画像、その他はテキストとして追加)"
          />
          <Button
            variant="contained"
            size="small"
            onClick={handleBulkInput}
            sx={{ mt: 1 }}
          >
            ブロックを作成
          </Button>
        </CardContent>
      </Card>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="blocks">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {blocks.map((block, index) => (
                <Draggable 
                  key={index.toString()} 
                  draggableId={index.toString()} 
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <Card sx={{ 
                        mb: 1, 
                        position: 'relative',
                        transition: 'all 0.2s ease'
                      }}>
                        <CardContent sx={{ 
                          py: 1, 
                          px: 2, 
                          '&:last-child': { pb: 1 },
                          transition: 'all 0.2s ease'
                        }}>
                          <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
                            <div {...provided.dragHandleProps}>
                              <DragIndicatorIcon sx={{ 
                                mr: 1, 
                                color: 'text.secondary', 
                                cursor: 'grab',
                                fontSize: '1.2rem' 
                              }} />
                            </div>
                            <Typography variant="caption" color="text.secondary">
                              {block.type === 'text' ? 'テキストブロック' : 
                               block.type === 'image' ? '画像ブロック' : '見出しブロック'}
                            </Typography>
                            <IconButton 
                              size="small" 
                              onClick={() => removeBlock(index)}
                              sx={{ ml: 'auto', p: 0.5 }}
                            >
                              <DeleteIcon sx={{ fontSize: '1.2rem' }} />
                            </IconButton>
                          </Box>

                          {block.type === 'text' ? (
                            <TextField
                              fullWidth
                              multiline
                              rows={focusedIndex === index ? 4 : 2}
                              size="small"
                              value={block.content || ''}
                              onChange={(e) => updateBlock(index, { ...block, content: e.target.value })}
                              onFocus={() => setFocusedIndex(index)}
                              onBlur={() => setFocusedIndex(null)}
                              placeholder="テキストを入力してください"
                              sx={{ 
                                transition: 'all 0.2s ease',
                                '& .MuiInputBase-input': {
                                  fontSize: '0.9rem',
                                },
                                '& .MuiOutlinedInput-root': {
                                  backgroundColor: focusedIndex === index ? 'rgba(15, 15, 15, 1)' : 'transparent',
                                }
                              }}
                            />
                          ) : block.type === 'heading' ? (
                            <TextField
                              fullWidth
                              size="small"
                              value={block.content || ''}
                              onChange={(e) => updateBlock(index, { ...block, content: e.target.value })}
                              onFocus={() => setFocusedIndex(index)}
                              onBlur={() => setFocusedIndex(null)}
                              placeholder="見出しを入力してください"
                              sx={{ 
                                '& .MuiInputBase-input': {
                                  fontSize: '1.1rem',
                                  fontWeight: 'bold'
                                },
                                '& .MuiOutlinedInput-root': {
                                  backgroundColor: focusedIndex === index ? 'rgba(15, 15, 15, 1)' : 'transparent',
                                }
                              }}
                            />
                          ) : (
                            <Box sx={{ 
                              display: 'flex', 
                              gap: 1,
                              flexDirection: focusedIndex === index ? 'column' : 'row'
                            }}>
                              <TextField
                                fullWidth
                                size="small"
                                label="画像URL"
                                value={block.url || ''}
                                onChange={(e) => updateBlock(index, { ...block, url: e.target.value })}
                                onFocus={() => setFocusedIndex(index)}
                                onBlur={() => setFocusedIndex(null)}
                                sx={{ 
                                  '& .MuiInputBase-input': {
                                    fontSize: '0.9rem',
                                  },
                                  '& .MuiOutlinedInput-root': {
                                    backgroundColor: focusedIndex === index ? 'rgba(15, 15, 15, 1)' : 'transparent',
                                  }
                                }}
                              />
                              <TextField
                                fullWidth
                                size="small"
                                label="キャプション"
                                value={block.caption || ''}
                                onChange={(e) => updateBlock(index, { ...block, caption: e.target.value })}
                                onFocus={() => setFocusedIndex(index)}
                                onBlur={() => setFocusedIndex(null)}
                                sx={{ 
                                  '& .MuiInputBase-input': {
                                    fontSize: '0.9rem',
                                  },
                                  '& .MuiOutlinedInput-root': {
                                    backgroundColor: focusedIndex === index ? 'rgba(15, 15, 15, 1)' : 'transparent',
                                  }
                                }}
                              />
                            </Box>
                          )}
                        </CardContent>
                      </Card>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <Box sx={{ mt: 1, display: 'flex', gap: 1 }}>
        <Button
          size="small"
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={addHeadingBlock}
        >
          見出し
        </Button>
        <Button
          size="small"
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={addTextBlock}
        >
          テキスト
        </Button>
        <Button
          size="small"
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={addImageBlock}
        >
          画像
        </Button>
      </Box>
    </Box>
  );
};

export default StoryEditor;