import React, { useState, useEffect } from 'react';
import { Container, Typography, Grid, Card, CardContent, CardActionArea } from '@mui/material';
import { Link } from 'react-router-dom';
import { getGameEngines } from '../../services/api';
import LoadingPlaceholder from '../../components/LoadingPlaceholder';

interface GameEngine {
  game_engine_id: number;
  name: string;
  slug: string;
}

const GameEngineListPage: React.FC = () => {
  const [gameEngines, setGameEngines] = useState<GameEngine[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchGameEngines = async () => {
      try {
        const data = await getGameEngines();
        setGameEngines(data);
      } catch (error) {
        console.error('ゲームエンジン一覧の取得に失敗しました', error);
      } finally {
        setLoading(false);
      }
    };

    fetchGameEngines();
  }, []);

  if (loading) {
    return <LoadingPlaceholder type="card" count={12} />;
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        ゲームエンジン一覧
      </Typography>
      <Grid container spacing={3}>
        {gameEngines.map((gameEngine) => (
          <Grid item xs={12} sm={6} md={4} key={gameEngine.game_engine_id}>
            <Card>
              <CardActionArea component={Link} to={`/games/game-engine/${gameEngine.game_engine_id}`}>
                <CardContent>
                  <Typography variant="h6" component="div">
                    {gameEngine.name}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default GameEngineListPage;