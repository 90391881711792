import React from 'react';
import { Typography, Box, Divider } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { SITE_NAME } from '../../constants/site';

const TermsOfServicePage: React.FC = () => {
  const Section: React.FC<{ title: string; intro?: string; items?: string[]; text?: string; index: number }> = ({ title, intro, items, text, index }) => (
    <Box sx={{ '&:not(:first-of-type)': { mt: 4 } }}>
      <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
        {`${index + 1}. ${title}`}
      </Typography>
      {intro && <Typography sx={{ mb: 2, color: 'text.secondary' }}>{intro}</Typography>}
      {items && (
        <Box component="ul" sx={{ pl: 2, m: 0, mb: text ? 2 : 0 }}>
          {items.map((item, index) => (
            <Typography component="li" key={index} sx={{ mb: 1, '&:last-child': { mb: 0 }, color: 'text.secondary' }}>
              {item}
            </Typography>
          ))}
        </Box>
      )}
      {text && <Typography sx={{ color: 'text.secondary' }}>{text}</Typography>}
    </Box>
  );

  const sections = [
    {
      title: '利用規約の適用',
      text: '本利用規約（以下「本規約」）は、当サイトが提供するすべてのサービスの利用に適用されます。ユーザーは本規約に同意の上、サービスを利用するものとします。'
    },
    {
      title: 'サービスの利用',
      intro: 'ユーザーは以下の事項を遵守してサービスを利用するものとします：',
      items: [
        'Googleアカウントによる認証を行い、正確な情報を提供すること',
        '定められた方法でのみサービスを利用すること',
        '他のユーザーの権利を侵害しない方法でサービスを利用すること',
        'サービスの運営を妨げる行為を行わないこと',
        '法令や公序良俗に反する行為を行わないこと'
      ]
    },
    {
      title: '利用年齢制限',
      intro: '当サイトの利用には以下の年齢制限が適用されます：',
      items: [
        '本サービスは13歳以上のユーザーを対象としています',
        '13歳未満の方は保護者の同意を得た上でのみ利用可能です',
        '一部のコンテンツやサービスには、別途年齢制限が設けられる場合があります',
        '虚偽の年齢（生年月日）の登録が発覚した場合、アカウント停止などの措置を講じることがあります'
      ],
      text: '当サイトは、ユーザーの年齢に応じて適切なコンテンツやサービスを提供するため、登録時に年齢確認を行います。'
    },
    {
      title: '禁止事項',
      intro: '以下の行為を禁止します：',
      items: [
        '不正確または虚偽の情報の提供',
        '他のユーザーになりすますこと',
        'サービスの不正利用や改変',
        '営利目的での利用（当サイトの許可がある場合を除く）',
        'スパム行為や迷惑行為',
        '著作権等の知的財産権の侵害',
        'その他、当サイトが不適切と判断する行為'
      ]
    },
    {
      title: 'ユーザーアカウント',
      intro: 'ユーザーアカウントについて以下の事項が適用されます：',
      items: [
        'アカウントの管理責任はユーザーにあります',
        'ユーザーIDは1ヶ月に1回のみ変更可能です',
        '不正利用が確認された場合、アカウントを停止または削除することがあります',
        'アカウントの譲渡・売買は禁止します',
        'セキュリティ上の問題が発生した場合は直ちに当サイトに報告してください'
      ]
    },
    {
      title: 'コンテンツの権利',
      text: 'ユーザーが投稿したレビューやコメント等のコンテンツの著作権はユーザーに帰属しますが、当サイトはそれらを利用・編集・削除する権利を有します。また、投稿されたコンテンツが第三者の権利を侵害している場合、当サイトは予告なく削除することがあります。'
    },
    {
      title: '免責事項',
      items: [
        'サービスの中断や停止による損害について、当サイトは責任を負いません',
        'ユーザー間のトラブルについて、当サイトは一切の責任を負いません',
        '投稿されたコンテンツの正確性について、当サイトは保証しません',
        'サービス内容は予告なく変更される場合があります'
      ]
    },
    {
      title: '規約の変更',
      text: '当サイトは、必要に応じて本規約を変更することがあります。変更後の規約は、当サイト上に表示した時点で効力を生じるものとします。'
    },
    {
      title: '準拠法と管轄裁判所',
      text: '本規約の解釈にあたっては日本法を準拠法とし、本サービスに関して紛争が生じた場合には、東京地方裁判所を第一審の専属的合意管轄裁判所とします。'
    }
  ];

  return (
    <>
      <Helmet>
        <title>利用規約 | {SITE_NAME}</title>
      </Helmet>
      <Typography variant="h4" component="h1" gutterBottom sx={{ mb: 4, mt: 4, fontWeight: 'bold' }}>
        利用規約
      </Typography>
      <Typography sx={{ mb: 4, color: 'text.secondary' }}>
        {SITE_NAME}（以下「当サイト」）をご利用いただく前に、以下の利用規約をよくお読みください。本サービスを利用することで、これらの規約に同意したものとみなされます。
      </Typography>
      <Divider sx={{ mb: 4 }} />
      {sections.map((section, index) => (
        <Section key={index} {...section} index={index} />
      ))}
      <Divider sx={{ my: 4 }} />
      <Box sx={{ color: 'text.secondary' }}>
        <Typography variant="body2">制定日：2024年11月1日<br />最終更新日：2024年11月1日</Typography>
      </Box>
    </>
  );
};

export default TermsOfServicePage;