import React from 'react';
import { Grid, Box } from '@mui/material';
import { GameStoryListItem } from '../../types/content';
import { StoryCard } from './StoryCard';
import StoryLoadingPlaceholder from './StoryLoadingPlaceholder';

interface StoriesSectionProps {
  stories: GameStoryListItem[];
  loading?: boolean;
}

export const StoriesSection: React.FC<StoriesSectionProps> = ({ stories, loading = false }) => {
  if (loading) {
    return <StoryLoadingPlaceholder type="list" />;
  }

  if (stories.length === 0) return null;

  return (
    <Grid container spacing={2} sx={{ mb: 6 }}>
      {stories.map((story) => (
        <Grid item xs={12} sm={6} md={4} key={story.id}>
          <StoryCard 
            story={story}
            to={`/game/${story.game}/stories/${story.id}`}
          />
        </Grid>
      ))}
    </Grid>
  );
};