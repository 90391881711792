import React, { useState, useEffect, useCallback } from 'react';
import { Typography, Grid, Box, Avatar, Button, Pagination, Skeleton, Tabs, Tab, Chip, useMediaQuery, Theme, Link, Tooltip, IconButton, Snackbar } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LinkIcon from '@mui/icons-material/Link';
import { getUserProfile, getGamesByStatus, updateUserProfile, getGamesByStatusForUser, getPublicGameStatuses, getProfileGameStatuses } from '../services/api';
import GameCard from '../components/GameCard';
import { UserProfile } from '../types/UserProfile';
import { Game } from '../types/Game';
import { AxiosResponse } from 'axios';
import LoadingPlaceholder from '../components/LoadingPlaceholder';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import GameListFilter from '../components/filter/GameListFilter';
import { Helmet } from 'react-helmet-async';
import { SITE_NAME } from '../constants/site';

const GAMES_PER_PAGE = 30;

interface ProfilePageProps {
  profile?: UserProfile;
  isPublic?: boolean;
}

const ProfilePage: React.FC<ProfilePageProps> = ({ profile: initialProfile, isPublic = false }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { username } = useParams<{ username: string }>();
  const queryParams = new URLSearchParams(location.search);
  const initialStatus = parseInt(queryParams.get('status') || '1', 10);
  const initialPage = parseInt(queryParams.get('page') || '1', 10);
  const initialSearchQuery = queryParams.get('query') || '';
  const initialOrder = queryParams.get('order') || 'default';

  const [profile, setProfile] = useState<UserProfile | null>(initialProfile || null);
  const [isEditing, setIsEditing] = useState(false);
  const [games, setGames] = useState<Game[]>([]);
  const [loadingProfile, setLoadingProfile] = useState(true);
  const [loadingGames, setLoadingGames] = useState(true);
  const [page, setPage] = useState(initialPage);
  const [totalPages, setTotalPages] = useState(0);
  const [tabValue, setTabValue] = useState(initialStatus);
  const [gameCounts, setGameCounts] = useState<number[]>([0, 0, 0, 0]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [searchQuery, setSearchQuery] = useState(initialSearchQuery);
  const [order, setOrder] = useState(initialOrder);
  const [totalGames, setTotalGames] = useState(0);
  const [gameStatuses, setGameStatuses] = useState<{
    [key: number]: {
      status: number | null;
      score: number | null;
      review_text: string | null;
      play_start_date: string | null;
      play_end_date: string | null;
      play_time: number | null;
      sub_status: number[] | null;
      score_tags: string[] | null;
    }
  }>({});

  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const fetchProfile = useCallback(async () => {
    if (isPublic || initialProfile) return;
    try {
      const response: AxiosResponse<UserProfile> = await getUserProfile();
      setProfile(response.data);
      setGameCounts(response.data.status_counts || [0, 0, 0, 0]);
    } catch (error) {
      console.error('プロフィールの取得に失敗しました', error);
    } finally {
      setLoadingProfile(false);
    }
  }, [isPublic, initialProfile]);

  useEffect(() => {
    if (isPublic && initialProfile) {
      setProfile(initialProfile);
      setGameCounts(initialProfile.status_counts || [0, 0, 0, 0]);
      setLoadingProfile(false);
    } else if (!isPublic) {
      fetchProfile();
    }
  }, [isPublic, initialProfile, fetchProfile]);

  const fetchGames = async (status: number, page: number, query: string, order: string) => {
    setLoadingGames(true);
    try {
      let response;
      if (isPublic && profile?.username) {
        response = await getGamesByStatusForUser(profile.username, status, page, query, order);
      } else {
        response = await getGamesByStatus(status, page, query, order);
      }
      setGames(response.data.results);
      setTotalPages(Math.ceil(response.data.count / GAMES_PER_PAGE));
      setTotalGames(response.data.count);
    } catch (error) {
      console.error('ゲームの取得に失敗しました', error);
    } finally {
      setLoadingGames(false);
    }
  };

  useEffect(() => {
    if (loadingProfile) return; // プロフィールのロードが完了していない場合は実行しない
    
    if (isPublic && !profile?.username) return;
    
    fetchGames(tabValue, page, searchQuery, order);
  }, [tabValue, page, isPublic, profile?.username, loadingProfile, searchQuery, order]);

  useEffect(() => {
    const fetchGameStatuses = async () => {
      if (!games.length) return;
      if (isPublic && !profile?.username) return;

      const gameIds = games.map(game => game.game_id);
      try {
        let statuses;
        if (isPublic && profile?.username) {
          statuses = await getPublicGameStatuses(profile.username, gameIds);
        } else if (!isPublic) {
          statuses = await getProfileGameStatuses(gameIds);
        }
        setGameStatuses(statuses || {});
      } catch (error) {
        console.error('ゲームステータスの一括取得に失敗しました', error);
      }
    };

    fetchGameStatuses();
  }, [games, isPublic, profile?.username]);

  const handleEditClick = () => {
    navigate('/profile/edit');
  };

  const handleSave = async (updatedProfile: UserProfile) => {
    try {
      const response = await updateUserProfile(updatedProfile);
      setIsEditing(false);
      setProfile(response.data);
    } catch (error: any) {
      if (error.response && error.response.data) {
        console.error('プロフィールの更新に失敗しました', error.response.data);
        throw error; // エラーを再スローして、ProfileEditFormで処理できるようにする
      }
    }
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    // URLを更新
    updateUrl(tabValue, value, searchQuery, order);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    setPage(1);
    // URLを更新
    updateUrl(newValue, 1, searchQuery, order);
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    setPage(1);
    // URLを更新
    updateUrl(tabValue, 1, query, order);
  };

  const handleOrderChange = (newOrder: string) => {
    setOrder(newOrder);
    setPage(1);
    // URLを更新
    updateUrl(tabValue, 1, searchQuery, newOrder);
  };

  // URLを更新する関数
  const updateUrl = (status: number, page: number, query: string, order: string) => {
    const newUrl = isPublic
      ? `/profile/${username}?status=${status}&page=${page}&query=${query}&order=${order}`
      : `/profile?status=${status}&page=${page}&query=${query}&order=${order}`;
    navigate(newUrl, { replace: true });
  };

  const TabLabel: React.FC<{ label: string; count: number; isSelected: boolean }> = ({ label, count, isSelected }) => (
    <Box sx={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', alignItems: 'center' }}>
      <Typography sx={{ mr: isSmallScreen ? 0 : 1, fontSize: isSmallScreen ? '0.75rem' : '0.875rem' }}>{label}</Typography>
      {isSelected ? (
        <Chip
          label={count}
          size="small"
          sx={{
            backgroundColor: 'primary.main',
            color: 'black',
            fontSize: isSmallScreen ? '0.625rem' : '0.75rem',
            height: isSmallScreen ? '16px' : '24px',
          }}
        />
      ) : (
        <Typography variant="body2" color="text.secondary" sx={{ fontSize: isSmallScreen ? '0.625rem' : '0.75rem' }}>
          /{count}
        </Typography>
      )}
    </Box>
  );

  const handleCopyLink = () => {
    const publicProfileUrl = `${window.location.origin}/profile/${profile?.username}`;
    navigator.clipboard.writeText(publicProfileUrl);
    setOpenSnackbar(true);
  };

  // 新しい関数を追加
  const handleClearFilter = () => {
    setSearchQuery('');
    setOrder('default');
    setPage(1);
    // URLを更新
    updateUrl(tabValue, 1, '', 'default');
    fetchGames(tabValue, 1, '', 'default');
  };

  return (
    <>
      <Helmet>
        <title>
          {isPublic && profile 
            ? `${profile.display_name || profile.username}(@${profile.username})のゲームライブラリ | ${SITE_NAME}`
            : `マイページ | ${SITE_NAME}`}
        </title>
      </Helmet>
      
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          {loadingProfile ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Skeleton variant="circular" width={80} height={80} />
              <Box sx={{ ml: 2, flexGrow: 1 }}>
                <Skeleton width="40%" height={32} />
                <Skeleton width="60%" />
                <Skeleton width="80%" />
              </Box>
            </Box>
          ) : (
            profile && (
              <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                <Avatar
                  src={profile.avatar || undefined}
                  sx={{ width: 80, height: 80 }}
                />
                <Box sx={{ ml: 2, flexGrow: 1 }}>
                  <Typography variant="h5">{profile.display_name || profile.username}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    @{profile.username} • {profile.location}
                  </Typography>
                  <Typography variant="body2" sx={{ mt: 1, mb: 1 }}>{profile.bio}</Typography>
                  {!isPublic && (
                    <Box sx={{ display: 'flex', alignItems: 'center', color: 'text.secondary', mt: 1 }}>
                      <Typography variant="body2" sx={{ mr: 1 }}>公開URL:</Typography>
                      <Link
                        href={`/profile/${profile.username}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        variant="body2"
                        sx={{ color: 'text.secondary', textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}
                      >
                        …/profile/{profile.username}
                      </Link>
                      <Tooltip title="リンクをコピー">
                        <IconButton onClick={handleCopyLink} size="small" sx={{ ml: 0.5, padding: '2px' }}>
                          <ContentCopyIcon sx={{ fontSize: '0.9rem' }} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                  {!isPublic && (
                    <Button variant="outlined" size="small" onClick={() => navigate('/profile/settings')} sx={{ mt: 1 }}>
                      プロフィール設定
                    </Button>
                  )}
                </Box>
              </Box>
            )
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          {/* 右側のカラムに追加のコンテンツを配置できます */}
        </Grid>
      </Grid>

      <Box sx={{ mt: 4, width: '100%', overflow: 'auto' }}>
        <Tabs 
          value={tabValue} 
          onChange={handleTabChange} 
          variant="scrollable"
          scrollButtons="auto"
          sx={{ 
            '& .MuiTab-root': {
              minWidth: isSmallScreen ? 'auto' : 'inherit',
              padding: isSmallScreen ? '6px 8px' : '6px 16px',
            }
          }}
        >
          <Tab 
            label={<TabLabel label="プレイ済み" count={gameCounts[0]} isSelected={tabValue === 1} />} 
            value={1} 
          />
          <Tab 
            label={<TabLabel label="プレイ中" count={gameCounts[1]} isSelected={tabValue === 2} />} 
            value={2} 
          />
          <Tab 
            label={<TabLabel label="積みゲー" count={gameCounts[2]} isSelected={tabValue === 3} />} 
            value={3} 
          />
          <Tab 
            label={<TabLabel label="気になる" count={gameCounts[3]} isSelected={tabValue === 4} />} 
            value={4} 
          />
        </Tabs>
        
        <Box sx={{ mt: 2 }}>
          <GameListFilter 
            onSearch={handleSearch} 
            onOrderChange={handleOrderChange}
            onClearFilter={handleClearFilter}
            totalGames={totalGames}
            initialSearchQuery={searchQuery}
            initialOrder={order}
          />
        </Box>
        {loadingGames ? (
          <LoadingPlaceholder type="card" count={GAMES_PER_PAGE} />
        ) : (
          <>
            <Grid container spacing={1.5}>
              {games.map((game) => (
                <Grid item xs={6} sm={4} md={2.4} key={game.game_id}>
                  <GameCard 
                    game={game} 
                    mode={isPublic ? 'public' : 'profile'}
                    username={isPublic ? profile?.username : undefined}
                    initialStatus={gameStatuses[game.game_id]?.status}
                    initialScore={gameStatuses[game.game_id]?.score}
                    initialReviewText={gameStatuses[game.game_id]?.review_text}
                    initialPlayStartDate={gameStatuses[game.game_id]?.play_start_date}
                    initialPlayEndDate={gameStatuses[game.game_id]?.play_end_date}
                    initialPlayTime={gameStatuses[game.game_id]?.play_time}
                    initialSubStatus={gameStatuses[game.game_id]?.sub_status}
                    initialScoreTags={gameStatuses[game.game_id]?.score_tags}
                  />
                </Grid>
              ))}
            </Grid>
            {totalPages > 1 && (
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}
              />
            )}
          </>
        )}
      </Box>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
        message="リンクがコピーされました"
      />
    </>
  );
};

export default ProfilePage;
