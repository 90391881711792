import React, { useState, useEffect } from 'react';
import { Button, Menu, MenuItem, ListItemIcon, ListItemText, Checkbox } from '@mui/material';
import { setGameSubStatus } from '../services/api';
import ReplayIcon from '@mui/icons-material/Replay';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

interface SubStatusButtonProps {
  gameId: number;
  status: number;
  initialSubStatus: number[];
  onSubStatusChange: (newSubStatus: number[]) => void;
}

const SubStatusButton: React.FC<SubStatusButtonProps> = ({ gameId, status, initialSubStatus, onSubStatusChange }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [subStatus, setSubStatus] = useState<number[]>(initialSubStatus);

  useEffect(() => {
    setSubStatus(initialSubStatus);
  }, [initialSubStatus]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubStatusChange = async (newSubStatus: number) => {
    try {
      const updatedSubStatus = subStatus.includes(newSubStatus)
        ? subStatus.filter(s => s !== newSubStatus)
        : [...subStatus, newSubStatus];
      
      await setGameSubStatus(gameId, updatedSubStatus);
      setSubStatus(updatedSubStatus);
      onSubStatusChange(updatedSubStatus);
    } catch (error) {
      console.error('サブステータスの更新に失敗しました', error);
    }
  };

  const getSubStatusIcon = (statusCode: number) => {
    switch (statusCode) {
      case 1: return <ReplayIcon fontSize="small" />;
      case 2: return <PriorityHighIcon fontSize="small" />;
      case 7: return <EmojiEventsIcon fontSize="small" />;
      default: return <ShoppingCartIcon fontSize="small" />;
    }
  };

  const getSubStatusText = (statusCode: number, subStatusCodes: number[]) => {
    const texts = subStatusCodes.map(code => {
      switch (statusCode) {
        case 1: // プレイ済み
          return code === 1 ? '再プレイ' : '実績コンプリート';
        case 2: // プレイ中
          return code === 1 ? '再プレイ中' : 'やり込みプレイ中';
        case 3: // 積みゲー
          return code === 1 ? '再プレイ' : '優先度 高';
        case 4: // 気になる
          return '購入予定';
        default:
          return '';
      }
    });
    return texts.length > 0 ? texts.join(', ') : 'サブステータス';
  };

  const renderMenuItem = (value: number, label: string) => (
    <MenuItem key={value} onClick={() => handleSubStatusChange(value)}>
      <ListItemIcon>
        {getSubStatusIcon(value)}
      </ListItemIcon>
      <ListItemText primaryTypographyProps={{ color: 'text.primary' }}>{label}</ListItemText>
      <Checkbox 
        checked={subStatus.includes(value)} 
        sx={{ 
          ml: 1,
          mr: 0,
          padding: '4px',
          '& .MuiSvgIcon-root': { fontSize: 20 }
        }}
        edge="end"
        size="small"
      />
    </MenuItem>
  );

  return (
    <>
      <Button
        onClick={handleClick}
        startIcon={subStatus.length > 0 
          ? getSubStatusIcon(subStatus[0]) 
          : <AddCircleOutlineIcon fontSize="small" sx={{ color: 'text.secondary' }} />
        }
        sx={{
          backgroundColor: 'background.paper',
          borderRadius: '12px',
          padding: '8px 16px',
          color: 'text.primary',
          '&:hover': {
            backgroundColor: 'action.hover',
          },
        }}
      >
        {getSubStatusText(status, subStatus)}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: '12px',
            boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
            mt: 1,
          }
        }}
      >
        {status === 1 && (
          <>
            {renderMenuItem(1, '再プレイ')}
            {renderMenuItem(7, '実績コンプリート')}
          </>
        )}
        {status === 2 && (
          <>
            {renderMenuItem(1, '再プレイ中')}
            {renderMenuItem(7, 'やり込みプレイ中')}
          </>
        )}
        {status === 3 && (
          <>
            {renderMenuItem(1, '再プレイ')}
            {renderMenuItem(2, '優先度 高')}
          </>
        )}
        {status === 4 && renderMenuItem(1, '購入予定')}
      </Menu>
    </>
  );
};

export default SubStatusButton;