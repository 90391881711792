import React, { useState, useEffect } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import ReviewList from './ReviewList';
import { getGameReviews } from '../../services/api';
import { Review } from '../../types/Review';

interface ReviewSectionProps {
  gameId: number;
  limit?: number;
  onTotalReviewsChange?: (total: number) => void;
}

const ReviewSection: React.FC<ReviewSectionProps> = ({ gameId, limit, onTotalReviewsChange }) => {
  const [reviews, setReviews] = useState<Review[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const { results, count } = await getGameReviews(gameId, undefined, limit);
        setReviews(results);
        if (onTotalReviewsChange) {
          onTotalReviewsChange(count);
        }
      } catch (error) {
        console.error('レビューの取得に失敗しました', error);
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, [gameId, limit, onTotalReviewsChange]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h6" gutterBottom>
        ユーザーレビュー
      </Typography>
      {reviews.length > 0 ? (
        <ReviewList reviews={reviews} />
      ) : (
        <Typography>まだレビューがありません。</Typography>
      )}
    </Box>
  );
};

export default ReviewSection;