import axios from 'axios';
import { Game, Genre, Platform, Franchise, Collection, GameEngine, Theme as GameTheme, Company } from '../types/Game';
import { Review } from '../types/Review';
import { AxiosResponse } from 'axios';
import { UserProfile } from '../types/UserProfile';
import { useNavigate } from 'react-router-dom';

interface SearchFilters {
  genres?: number[];
  platforms?: number[];
  themes?: number[];
  year?: number | '';
  order?: string;
}

export const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api';

const api = axios.create({
  baseURL: API_BASE_URL,
});

let isRefreshing = false;
let refreshSubscribers: ((token: string) => void)[] = [];

const onRrefreshed = (token: string) => {
  refreshSubscribers.map((callback) => callback(token));
};

const addRefreshSubscriber = (callback: (token: string) => void) => {
  refreshSubscribers.push(callback);
};

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve) => {
          addRefreshSubscriber((token: string) => {
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
            resolve(api(originalRequest));
          });
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const refreshToken = localStorage.getItem('refreshToken');
      if (!refreshToken) {
        await logout();
        return Promise.reject(error);
      }

      try {
        const response = await axios.post(`${API_BASE_URL}/auth/token/refresh/`, {
          refresh: refreshToken,
        });
        const newToken = response.data.access;
        localStorage.setItem('token', newToken);
        isRefreshing = false;
        onRrefreshed(newToken);
        refreshSubscribers = [];
        originalRequest.headers['Authorization'] = 'Bearer ' + newToken;
        return api(originalRequest);
      } catch (err) {
        isRefreshing = false;
        await logout();
        return Promise.reject(err);
      }
    }
    return Promise.reject(error);
  }
);

export const getGames = () => api.get('/games/');
export const getRecentGames = (): Promise<{ data: { results: Game[] } }> => api.get('/games/recent/?limit=10');
export const getPopularGames = (): Promise<{ data: { results: Game[] } }> => api.get('/games/popular/?limit=10');
export const getGameDetails = async (game_id: number) => {
  const response = await axios.get(`${API_BASE_URL}/games/${game_id}/`);
  return response.data;
};
export const searchGames = (query: string, page: number, filters: SearchFilters = {}): Promise<{ results: Game[], count: number }> => {
  const params = new URLSearchParams({
    q: query,
    page: page.toString(),
  });

  if (filters.genres && filters.genres.length > 0) {
    params.append('genres', filters.genres.join(','));
  }
  if (filters.platforms && filters.platforms.length > 0) {
    params.append('platforms', filters.platforms.join(','));
  }
  if (filters.themes && filters.themes.length > 0) {
    params.append('themes', filters.themes.join(','));
  }
  if (filters.year) {
    params.append('year', filters.year.toString());
  }
  if (filters.order && filters.order !== '') {
    params.append('order', filters.order);
  }

  console.log('Sending request with params:', params.toString());

  return api.get(`/games/search/?${params.toString()}`).then(response => {
    console.log('API Response:', JSON.stringify(response.data, null, 2));
    return {
      results: response.data.results || [],
      count: response.data.count || 0
    };
  });
};

// 新しく追加する関数
export const getPlatforms = (): Promise<Platform[]> => 
  api.get('/platforms/').then(response => response.data);

export const getGenres = async (): Promise<Genre[]> => {
  const response = await api.get('/genres/');
  return response.data.results || [];
};

export const getGamesByPlatform = async (id: string, page: number = 1) => {
  const response = await axios.get(`${API_BASE_URL}/games/by_platform/?id=${id}&page=${page}`);
  return response.data;
};

export const getGamesByGenre = async (id: string, page: number = 1) => {
  const response = await axios.get(`${API_BASE_URL}/games/by_genre/?id=${id}&page=${page}`);
  return response.data;
};

export const getFranchises = (): Promise<Franchise[]> =>
  api.get('/franchises/').then(response => response.data);

export const getCollections = (): Promise<Collection[]> =>
  api.get('/collections/').then(response => response.data);

export const getGamesByFranchise = async (id: string, page: number = 1) => {
  const response = await axios.get(`${API_BASE_URL}/games/by_franchise/?id=${id}&page=${page}`);
  return response.data;
};

export const getGamesByCollection = async (id: string, page: number = 1) => {
  const response = await axios.get(`${API_BASE_URL}/games/by_collection/?id=${id}&page=${page}`);
  return response.data;
};

export const getGameEngines = (): Promise<GameEngine[]> =>
  api.get('/game-engines/').then(response => response.data);

export const getThemes = async (): Promise<GameTheme[]> => {
  const response = await api.get('/themes/');
  return response.data.results || [];
};

export const getGamesByGameEngine = async (id: string, page: number = 1) => {
  const response = await axios.get(`${API_BASE_URL}/games/by_game_engine/?id=${id}&page=${page}`);
  return response.data;
};

export const getGamesByTheme = async (id: string, page: number = 1) => {
  const response = await axios.get(`${API_BASE_URL}/games/by_theme/?id=${id}&page=${page}`);
  return response.data;
};

export const getCompanies = (): Promise<Company[]> =>
  api.get('/companies/').then(response => response.data);

export const getGamesByCompany = async (id: string, page: number = 1) => {
  const response = await axios.get(`${API_BASE_URL}/games/by_company/?id=${id}&page=${page}`);
  return response.data;
};

export const getUserProfile = async (): Promise<AxiosResponse<UserProfile>> => {
  return await api.get('/profile/');
};

export const updateUserProfile = (data: any) => api.patch('/profile/', data);

export const checkProfileCompletion = async () => {
  try {
    const response = await api.get('/profile/completion/');
    return response.data.is_complete;
  } catch (error) {
    console.error('プロフィール完成度チェックに失敗しました', error);
    return false;
  }
};

export const logout = async () => {
  try {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('user');
    // カスタムイベントを発火してヘッダーの状態を更新
    const event = new CustomEvent('logout');
    window.dispatchEvent(event);
  } catch (error) {
    console.error('ログアウトに失敗しました', error);
  }
};

export const setGameStatus = async (gameId: number, status: number | null) => {
  try {
    const response = await api.post(`/user-game-status/set_status/`, { 
      game_id: gameId, 
      status: status === null ? 'delete' : status 
    });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.error);
    } else {
      throw new Error('ゲームステータスの更新に失敗しました');
    }
  }
};

export const setGameReview = (gameId: number, score: number | null, reviewText: string | null, playStartDate: string | null, playEndDate: string | null, playTime: number | null, scoreTags: number[]) =>
  api.post(`/user-game-status/set_review/`, { game_id: gameId, score, review_text: reviewText, play_start_date: playStartDate, play_end_date: playEndDate, play_time: playTime, score_tags: scoreTags });

export const getUserGameStatus = async (gameId: number): Promise<{ 
  status: number | null, 
  score: number | null, 
  reviewText: string | null, 
  playStartDate: string | null, 
  playEndDate: string | null, 
  playTime: number | null, 
  subStatus: number[],
  scoreTags: number[]
}> => {
  try {
    const response = await api.get(`/user-game-status/get_status/`, { params: { game_id: gameId } });
    return {
      status: response.data.status,
      score: response.data.score,
      reviewText: response.data.review_text,
      playStartDate: response.data.play_start_date,
      playEndDate: response.data.play_end_date,
      playTime: response.data.play_time,
      subStatus: response.data.sub_status || [],
      scoreTags: response.data.score_tags || []
    };
  } catch (error) {
    console.error('ゲームステータスの取得に失敗しました', error);
    return { status: null, score: null, reviewText: null, playStartDate: null, playEndDate: null, playTime: null, subStatus: [], scoreTags: [] };
  }
};

export const getGamesByStatus = (status: number, page: number = 1, query: string = '', order: string = 'default') =>
  api.get(`/user-game-status/by_status/`, { params: { status, page, query, order } });

export const getUserProfileByUsername = (username: string): Promise<UserProfile> =>
  api.get(`/profile/${username}/`).then(response => response.data);

export const getGamesByStatusForUser = (username: string, status: number, page: number = 1, query: string = '', order: string = 'default') =>
  api.get(`/user-game-status/by_status/${username}/`, { params: { status, page, query, order } });

export const setGameSubStatus = (gameId: number, subStatus: number[]): Promise<{ game_id: number, sub_status: number[] }> =>
  api.post(`/user-game-status/set_sub_status/`, { game_id: gameId, sub_status: subStatus });

export const getGameReviews = async (gameId: number, page?: number, limit?: number): Promise<{ results: Review[], count: number }> => {
  const params = new URLSearchParams();
  if (limit) params.append('limit', limit.toString());
  if (page) params.append('page', page.toString());
  
  const response = await api.get(`/games/${gameId}/reviews/?${params.toString()}`);
  return {
    results: response.data.results.map((review: any) => ({
      id: review.id,
      username: review.username,
      display_name: review.display_name,
      avatar: review.avatar,
      game: review.game,
      review_text: review.review_text,
      created_at: review.created_at,
      updated_at: review.updated_at,
      score: review.score,
      score_tags: review.score_tags,
      play_time: review.play_time,
      play_start_date: review.play_start_date,
      play_end_date: review.play_end_date,
      first_release_date: review.first_release_date,
      user_birth_date: review.user_birth_date,
      age_privacy: review.age_privacy
    })),
    count: response.data.count
  };
};

export const getPublicUserGameStatus = async (username: string, gameId: number): Promise<{ 
  status: number | null, 
  score: number | null, 
  reviewText: string | null, 
  playStartDate: string | null, 
  playEndDate: string | null, 
  playTime: number | null, 
  subStatus: number[],
  scoreTags: number[]
}> => {
  try {
    const response = await api.get(`/user-game-status/${username}/get_status/`, { params: { game_id: gameId } });
    return {
      status: response.data.status,
      score: response.data.score,
      reviewText: response.data.review_text,
      playStartDate: response.data.play_start_date,
      playEndDate: response.data.play_end_date,
      playTime: response.data.play_time,
      subStatus: response.data.sub_status || [],
      scoreTags: response.data.score_tags || []
    };
  } catch (error) {
    console.error('パブリックゲームステータスの取得に失敗しました', error);
    return { status: null, score: null, reviewText: null, playStartDate: null, playEndDate: null, playTime: null, subStatus: [], scoreTags: [] };
  }
};

export const getTrendingNewReleases = (limit: number = 10, offset: number = 0): Promise<{ data: { results: Game[], next: number | null } }> => 
  api.get(`/trending-new-releases/?limit=${limit}&offset=${offset}`);

export const getAnticipatedGames = (limit: number = 10, offset: number = 0): Promise<{ data: { results: Game[], next: number | null } }> => 
  api.get(`/anticipated-games/?limit=${limit}&offset=${offset}`);

export const getPopular2024Games = (limit: number = 10, offset: number = 0): Promise<{ data: { results: Game[], next: number | null } }> => 
  api.get(`/popular-2024-games/?limit=${limit}&offset=${offset}`);

export const getPopularGamesByYear = (year: number, ratingThreshold: number = 70): Promise<{ data: { results: Game[], next: number | null, year: number } }> => 
  api.get(`/popular-games-by-year/?year=${year}&rating_threshold=${ratingThreshold}`);

export const getStatusLimits = async (): Promise<{ status: number; name: string; baseLimit: number; extensionLimit: number }[]> => {
  try {
    const response = await api.get('/user-profile/status-limits/');
    return response.data;
  } catch (error) {
    console.error('ステータス制限の取得に失敗しました', error);
    throw error;
  }
};

// 一括取得の関数のみ残す
export const getBasicGameStatuses = async (gameIds: number[]): Promise<{ [key: number]: number | null }> => {
  try {
    const response = await api.get(`/user-game-status/get_basic_statuses/`, {
      params: { game_ids: gameIds.join(',') }
    });
    return response.data;
  } catch (error) {
    console.error('基本ゲームステータスの一括取得に失敗しました', error);
    return {};
  }
};

export const getProfileGameStatuses = async (gameIds: number[]): Promise<{ 
  [key: number]: {
    status: number | null;
    score: number | null;
    review_text: string | null;
    play_start_date: string | null;
    play_end_date: string | null;
    play_time: number | null;
    sub_status: number[] | null;
    score_tags: string[] | null;
  } 
}> => {
  try {
    const response = await api.get(`/user-game-status/get_profile_statuses/`, {
      params: { game_ids: gameIds.join(',') }
    });
    return response.data;
  } catch (error) {
    console.error('プロフィールゲームステータスの一括取得に失敗しました', error);
    return {};
  }
};

export const getPublicGameStatuses = async (username: string, gameIds: number[]): Promise<{
  [key: number]: {
    status: number | null;
    score: number | null;
    review_text: string | null;
    play_start_date: string | null;
    play_end_date: string | null;
    play_time: number | null;
    sub_status: number[] | null;
    score_tags: string[] | null;
  }
}> => {
  try {
    const response = await api.get(`/public-user-game-status/${username}/get_public_statuses/`, {
      params: { game_ids: gameIds.join(',') }
    });
    return response.data;
  } catch (error) {
    console.error('パブリックゲームステータスの一括取得に失敗しました', error);
    return {};
  }
};

// 誕生日のみを取得する関数
export const getUserBirthDate = async (): Promise<string | null> => {
  try {
    const response = await api.get('/user/birth-date/');
    return response.data.birth_date;
  } catch (error) {
    console.error('誕生日の取得に失敗しました', error);
    return null;
  }
};

export default api;
