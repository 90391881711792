import React from 'react';
import { Typography, Box, Paper, Avatar, Button } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import GoogleLoginButton from '../components/auth/GoogleLogin';

const LoginPage: React.FC = () => {
  const handleLoginSuccess = (user: any) => {
    // ヘッダーの状態を更新するためのカスタムイベントを発火
    const event = new CustomEvent('loginSuccess', { detail: user });
    window.dispatchEvent(event);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 8 }}>
      <Paper elevation={3} sx={{ p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: 400, width: '100%' }}>
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography variant="h5" component="h1" gutterBottom>
          ログイン
        </Typography>
        <GoogleLoginButton onLoginSuccess={handleLoginSuccess} />
        <Button variant="contained" color="primary" sx={{ mt: 2 }}>
          他のログイン方法
        </Button>
      </Paper>
    </Box>
  );
};

export default LoginPage;