import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Container, Fade, useTheme, useMediaQuery } from '@mui/material';
import { getGameStory } from '../../../services/contents_api';
import { GameStory } from '../../../types/content';
import LoadingPlaceholder from '../../../components/LoadingPlaceholder';
import { styled } from '@mui/material/styles';
import GameCard from '../../../components/GameCard';
import StoryLoadingPlaceholder from '../../../components/story/StoryLoadingPlaceholder';

const HeroContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  maxWidth: '680px',
  margin: '24px auto',
  aspectRatio: '4/4',
  overflow: 'hidden',
  borderRadius: '24px',
  boxShadow: '0 8px 32px rgba(0,0,0,0.12)',
  [theme.breakpoints.down('sm')]: {
    width: '95%',
    margin: '16px auto',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '50%',
    background: 'linear-gradient(to bottom, transparent, rgba(0,0,0,0.95))',
  }
}));

const HeroImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

const TitleContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: '5%',
  left: '50%',
  transform: 'translateX(-50%)',
  width: '90%',
  maxWidth: '600px',
  color: '#fff',
  zIndex: 2,
}));

const ContentContainer = styled(Container)(({ theme }) => ({
  position: 'relative',
  marginTop: theme.spacing(6),
  zIndex: 1,
  backgroundColor: 'transparent',
  padding: 0,
}));

const TextBlock = styled(Typography)(({ theme }) => ({
  maxWidth: '680px',
  margin: '0 auto',
  padding: theme.spacing(0, 3),
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-word',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 2),
  },
}));

const StoryImage = styled('img')(({ theme }) => ({
  width: '100%',
  borderRadius: '12px',
  marginBottom: theme.spacing(2),
  boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
}));

const ImageBlock = styled(Box)(({ theme }) => ({
  maxWidth: '720px',
  margin: '0 auto',
  padding: theme.spacing(0, 3),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 2),
  },
}));

const HeadingBlock = styled(Typography)(({ theme }) => ({
  maxWidth: '680px',
  margin: '0 auto',
  padding: theme.spacing(0, 3),
  fontSize: '1.5rem',
  fontWeight: 'bold',
  color: theme.palette.text.primary,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 2),
    fontSize: '1.3rem',
  },
}));

const GameStoryDetailPage: React.FC = () => {
  const { storyId } = useParams<{ storyId: string }>();
  const [story, setStory] = useState<GameStory | null>(null);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchStory = async () => {
      try {
        const data = await getGameStory(Number(storyId));
        setStory(data);
      } catch (error) {
        console.error('ストーリーの取得に失敗しました', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStory();
  }, [storyId]);

  if (loading) {
    return <StoryLoadingPlaceholder />;
  }

  if (!story) {
    return (
      <Box>
        <Typography color="error">
          ストーリーの取得に失敗しました。
        </Typography>
      </Box>
    );
  }

  return (
    <Fade in timeout={1000}>
      <Box sx={{ 
        pt: { xs: 2, sm: 3 },
        pb: { xs: 4, sm: 6 }
      }}>
        <HeroContainer>
          <HeroImage src={story.hero_image_url} alt={story.title} />
          <TitleContainer>
            <Typography 
              variant={isMobile ? "h4" : "h3"} 
              component="h1"
              sx={{ 
                mb: 1,
                fontWeight: 'bold',
                textShadow: '0 2px 4px rgba(0,0,0,0.3)'
              }}
            >
              {story.title}
            </Typography>
            <Typography 
              variant="h6" 
              component="h2"
              sx={{ 
                opacity: 0.8,
                fontWeight: 'normal',
                textShadow: '0 2px 4px rgba(0,0,0,0.3)'
              }}
            >
              {story.game_info.japanese_name || story.game_info.name}
            </Typography>
          </TitleContainer>
        </HeroContainer>

        <ContentContainer maxWidth="lg">
          {story.content.blocks.map((block, index) => (
            <Fade in timeout={500 + index * 100} key={index}>
              <Box sx={{ mb: 6 }}>
                {block.type === 'text' ? (
                  <TextBlock
                    variant="body1"
                    sx={{
                      lineHeight: 1.7,
                      fontSize: isMobile ? '.9rem' : '1.1rem',
                      color: theme.palette.text.primary,
                      letterSpacing: '0.01em'
                    }}
                  >
                    {block.content}
                  </TextBlock>
                ) : block.type === 'heading' ? (
                  <HeadingBlock variant="h2">
                    {block.content}
                  </HeadingBlock>
                ) : (
                  <ImageBlock>
                    <StoryImage
                      src={block.url}
                      alt={block.caption || ''}
                      loading="lazy"
                    />
                    {block.caption && (
                      <Typography 
                        variant="caption" 
                        sx={{
                          display: 'block',
                          textAlign: 'center',
                          mt: 0,
                          color: theme.palette.text.secondary,
                          fontSize: '0.75rem'
                        }}
                      >
                        {block.caption}
                      </Typography>
                    )}
                  </ImageBlock>
                )}
              </Box>
            </Fade>
          ))}
          {story.game_info && (
            <Box sx={{ 
              maxWidth: '220px',
              mx: 'auto',
              mt: 8,
              mb: 4
            }}>
              <GameCard game={story.game_info} />
            </Box>
          )}
        </ContentContainer>
      </Box>
    </Fade>
  );
};

export default GameStoryDetailPage;