import api from './api';
import { GameStory, GameStoryListItem } from '../types/content';

export const getGameStories = async (gameId: number): Promise<GameStoryListItem[]> => {
  const response = await api.get(`/stories/?game_id=${gameId}`);
  return response.data.results;
};

export const getAllGameStories = async (): Promise<GameStoryListItem[]> => {
  const response = await api.get('/stories/all_stories/');
  return response.data;
};

export const getGameStory = async (storyId: number) => {
  const response = await api.get(`/stories/${storyId}/`);
  return response.data;
};

export const createGameStory = async (storyData: Partial<GameStory>) => {
  const response = await api.post('/stories/', storyData);
  return response.data;
};

export const updateGameStory = async (storyId: number, storyData: Partial<GameStory>) => {
  try {
    const response = await api.patch(`/stories/${storyId}/`, storyData);
    return response.data;
  } catch (error) {
    console.error('ストーリーの更新に失敗しました', error);
    throw error;
  }
};

export const deleteGameStory = async (storyId: number) => {
  await api.delete(`/stories/${storyId}/`);
};