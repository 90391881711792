import React from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Grid, 
  Paper,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
  useMediaQuery,
  alpha,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { 
  LibraryBooks,
  Search,
  RateReview,
  People,
  Security,
  Update,
  ContactSupport,
} from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import { SITE_NAME } from '../constants/site';

const AboutPage: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const features = [
    {
      icon: <LibraryBooks />,
      title: 'ゲームライブラリ',
      description: 'プレイ状況からプレイ時間まで、あなたのゲーム体験を記録。詳細なステータス管理で、ゲーム体験をより深く残せます。',
      items: [
        'プレイ中・積みゲーなどのステータス管理',
        'プレイ時間やプレイ時期の記録',
      ]
    },
    {
      icon: <Search />,
      title: 'データベース',
      description: '18万本以上の豊富なゲームデータベースから、新しいゲームとの出会いを。プラットフォームやジャンルなど、多彩な切り口で探索できます。',
      items: [
        '検索・フィルタリング機能',
        'プラットフォーム、リリース時期などによる絞り込み',
      ]
    },
    {
      icon: <RateReview />,
      title: 'レビュー・評価',
      description: '世代指標やスコア補正など独自の評価システムで、より深い洞察とともにゲーム体験を記録。あなたならではの視点で評価できます。',
      items: [
        'スコア補正による主観的評価要因の表明',
        '世代指標による分析と考察',
      ]
    }
  ];

  return (
    <>
      <Helmet>
        <title>PULSEについて | {SITE_NAME}</title>
        <meta 
          name="description" 
          content="PULSEは、ゲーム体験を記録・管理できる究極のゲームライブラリです。18万本以上のゲームデータベースであなたのゲーム体験をより豊かにします。" 
        />
      </Helmet>

      <Box sx={{ 
        background: `linear-gradient(135deg, ${theme.palette.primary.main}22 0%, ${theme.palette.background.default} 100%)`,
        pt: 8,
        pb: 6,
        borderRadius: '16px',
        mb: 4
      }}>
        <Container maxWidth="lg">
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
            <img src="/pulse_logo.png" alt="PULSE" style={{ width: 300, maxWidth: '60%' }} />
          </Box>
          <Typography
            variant="h5"
            align="center"
            paragraph
            sx={{
              color: 'text.secondary',
              maxWidth: '800px',
              mx: 'auto',
              mb: 4
            }}
          >
            ゲームは、記録するともっと面白い。
            あなたが今までプレイしてきた数々のゲーム体験が、一つの場所に美しく整理され、いつでも振り返ることができます
          </Typography>
        </Container>
      </Box>

      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Paper 
                elevation={0}
                sx={{ 
                  p: 4,
                  height: '100%',
                  backgroundColor: alpha(theme.palette.background.paper, 0.4),
                  borderRadius: '16px',
                  backdropFilter: 'blur(10px)',
                  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                  }
                }}
              >
                <Box sx={{ 
                  color: theme.palette.primary.main, 
                  mb: 2,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '48px',  // アイコン背景のサイズ指定
                  height: '48px',
                  borderRadius: '12px',  // 角丸の四角形
                  backgroundColor: alpha(theme.palette.primary.main, 0.1),
                  // オプション: ホバー時に微かに光る効果
                  transition: 'all 0.2s',
                  '&:hover': {
                    backgroundColor: alpha(theme.palette.primary.main, 0.15),
                    transform: 'scale(1.05)',
                  }
                }}>
                  {feature.icon}
                </Box>
                <Typography 
                  variant="h6" 
                  component="h2"
                  sx={{ 
                    mb: 1,
                    fontWeight: 'bold'
                  }}
                >
                  {feature.title}
                </Typography>
                <Typography color="text.secondary" paragraph>
                  {feature.description}
                </Typography>
                <List dense>
                  {feature.items.map((item, itemIndex) => (
                    <ListItem 
                      key={itemIndex}
                      alignItems="flex-start"
                      sx={{ 
                        pt: 0.5,
                        pb: 0.5
                      }}
                    >
                      <ListItemIcon 
                        sx={{ 
                          minWidth: 32,
                          mt: '7px'
                        }}
                      >
                        <Box
                          sx={{
                            width: 6,
                            height: 6,
                            backgroundColor: theme.palette.primary.main,
                            borderRadius: '50%',
                            opacity: 0.8
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText 
                        primary={item}
                        primaryTypographyProps={{
                          color: 'text.secondary',
                          fontSize: '0.8rem',
                          lineHeight: 1.7
                        }}
                        sx={{ m: 0 }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Grid>
          ))}
        </Grid>

        <Box sx={{ mt: 8, textAlign: 'center' }}>
          <Typography variant="h4" component="h2" gutterBottom>
            さあ、始めましょう
          </Typography>
          <Typography color="text.secondary" paragraph>
           素晴らしいゲーム体験は、記録されることで、より一層輝きを増します
          </Typography>
          <Box sx={{ mt: 3 }}>
            <Button
              component={RouterLink}
              to="/login"
              variant="contained"
              size="large"
              sx={{ 
                mr: 2,
                borderRadius: '12px'
              }}
            >
              はじめる
            </Button>
            <Button
              component={RouterLink}
              to="/search"
              variant="outlined"
              size="large"
              sx={{
                borderRadius: '12px'
              }}
            >
              ゲームを探す
            </Button>
          </Box>
        </Box>

        <Paper 
          elevation={0}
          sx={{ 
            mt: 8, 
            p: 4, 
            borderRadius: '16px', 
            backgroundColor: alpha(theme.palette.background.paper, 0.4),
            backdropFilter: 'blur(10px)',
            border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
            position: 'relative',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: `radial-gradient(circle at 30% 40%, ${alpha(theme.palette.primary.main, 0.05)} 0%, transparent 60%),
                          radial-gradient(circle at 70% 60%, ${alpha(theme.palette.secondary.main, 0.05)} 0%, transparent 60%)`,
              borderRadius: 'inherit',
              pointerEvents: 'none',
            }
          }}
        >
          <Box sx={{ textAlign: 'center', position: 'relative' }}>
            <img src="/logo192.png" alt="PULSE" style={{ width: '64px', marginBottom: '16px' }} />
            <Typography variant="h5" component="h2" gutterBottom>
              アーリーアクセス
            </Typography>
            <Typography color="text.secondary" sx={{ maxWidth: '650px', mx: 'auto' }}>
              PULSEは現在、早期アクセス版として公開しています。今後、さらなる拡張や新機能の追加を予定しており、
              より充実したゲーム体験の記録と管理を実現していきます。
              みなさまからのフィードバックをお待ちしています。
            </Typography>
          </Box>
        </Paper>

        <Box sx={{ mt: 8 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Paper 
                elevation={0}
                sx={{ 
                  p: 3, 
                  borderRadius: '16px',
                  backgroundColor: alpha(theme.palette.background.paper, 0.4),
                  backdropFilter: 'blur(10px)',
                  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                  }
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Security sx={{ color: 'primary.main', mr: 2 }} />
                  <Typography variant="h6" component="h2">
                    プライバシーとセキュリティ
                  </Typography>
                </Box>
                <Typography color="text.secondary" paragraph>
                  ユーザーのプライバシーとデータの保護を最優先に考えています。
                  SSL/TLS暗号化通信の使用、アクセス権限の適切な管理など、
                  セキュリティ対策を徹底しています。
                </Typography>
                <Button 
                  component={RouterLink}
                  to="/privacy-policy"
                  variant="text"
                >
                  プライバシーポリシーを見る
                </Button>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper 
                elevation={0}
                sx={{ 
                  p: 3, 
                  borderRadius: '16px',
                  backgroundColor: alpha(theme.palette.background.paper, 0.4),
                  backdropFilter: 'blur(10px)',
                  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                  }
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <ContactSupport sx={{ color: 'primary.main', mr: 2 }} />
                  <Typography variant="h6" component="h2">
                    サポート
                  </Typography>
                </Box>
                <Typography color="text.secondary" paragraph>
                  ご不明な点やご要望がございましたら、お気軽にお問い合わせください。
                  サービスの改善に向けて、ユーザーの皆様からのフィードバックをお待ちしています。
                </Typography>
                <Button 
                  href="https://dev.plsdb.com/contact"
                  target="_blank"
                  rel="noopener noreferrer"
                  variant="text"
                >
                  お問い合わせ
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default AboutPage;