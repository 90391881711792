import React from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Paper,
  Grid,
  useTheme,
  alpha 
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { 
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator 
} from '@mui/lab';
import { 
  AutoStories,
  Explore,
  Psychology,
  Lightbulb,
  Collections,
  EmojiEvents
} from '@mui/icons-material';
import { SITE_NAME } from '../constants/site';

const WhyRecordPage: React.FC = () => {
  const theme = useTheme();

  const reasons = [
    {
      icon: <AutoStories />,
      title: "体験を記録に",
      description: "ゲームで感じた興奮、感動、驚き。一つひとつの体験が、あなたのかけがえのない記録となっていきます。"
    },
    {
      icon: <Psychology />,
      title: "いつまでも鮮やかに",
      description: "プレイの軌跡を振り返ることで、当時の感動が鮮やかによみがえります。時を経ても、その体験は色褪せることはありません。"
    },
    {
      icon: <Explore />,
      title: "次なる冒険へ",
      description: "ゲームを整理することで、次なる冒険への道筋が見えてきます。あなたのゲームライフは、まだまだ広がっていきます。"
    }
  ];

  const benefits = [
    {
      icon: <Collections />,
      title: "体験の結晶化",
      description: "プレイ時間、達成度、感想...。断片的な記憶は、記録することで価値ある体験として結晶化します。"
    },
    {
      icon: <Lightbulb />,
      title: "新たな発見",
      description: "他のプレイヤーとの体験共有は、懐かしい記憶との再会や、新たな視点との出会いをもたらします。"
    },
    {
      icon: <EmojiEvents />,
      title: "コレクションを楽しむ",
      description: "お気に入りのシリーズ、心に残ったシーン、プレイの軌跡...。記録することそのものが、新しい楽しみとなっていきます。"
    }
  ];

  return (
    <>
      <Helmet>
        <title>なぜ記録するのか | {SITE_NAME}</title>
        <meta 
          name="description" 
          content="ゲームは単なる娯楽を超えた体験の結晶です。その体験を記録することで、新たな価値が生まれます。PULSEは、あなたのゲーム体験をより豊かにする記録の場を提供します。" 
        />
      </Helmet>

      <Box sx={{ 
        background: `linear-gradient(135deg, ${theme.palette.primary.main}22 0%, ${theme.palette.background.default} 100%)`,
        pt: 8,
        pb: 6,
        borderRadius: '16px',
        mb: 4
      }}>
        <Container maxWidth="md">
          <Box sx={{ textAlign: 'center', mb: 6 }}>
            <img src="/logo192.png" alt="PULSE" style={{ height: '60px', marginBottom: '2rem' }} />
            <Typography variant="h3" component="h1" gutterBottom sx={{ 
              fontWeight: 'bold',
              background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
              backgroundClip: 'text',
              textFillColor: 'transparent',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}>
              なぜ記録するのか
            </Typography>
            <Typography variant="h5" sx={{ color: 'text.secondary', mt: 3 }}>
              ゲームは、単なる娯楽を超えた体験の結晶です。
              <br />
              その体験を記録することで、新たな価値が生まれます。
            </Typography>
          </Box>
        </Container>
      </Box>

      <Container maxWidth="lg">
        <Grid container spacing={4} sx={{ mb: 8 }}>
          {reasons.map((reason, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Paper 
                elevation={0}
                sx={{ 
                  p: 4,
                  height: '100%',
                  backgroundColor: alpha(theme.palette.background.paper, 0.4),
                  borderRadius: '16px',
                  backdropFilter: 'blur(10px)',
                  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                  }
                }}
              >
                <Box sx={{ 
                  color: theme.palette.primary.main, 
                  mb: 2,
                  opacity: 0.8
                }}>
                  {reason.icon}
                </Box>
                <Typography variant="h5" gutterBottom>
                  {reason.title}
                </Typography>
                <Typography color="text.secondary">
                  {reason.description}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>

        <Box sx={{ 
          my: 12,
          position: 'relative',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: -40,
            left: 0,
            right: 0,
            bottom: -40,
            background: `radial-gradient(circle at 30% 50%, ${alpha(theme.palette.primary.main, 0.08)} 0%, transparent 70%),
                        radial-gradient(circle at 70% 50%, ${alpha(theme.palette.secondary.main, 0.08)} 0%, transparent 70%)`,
            borderRadius: '24px',
            zIndex: -1,
          }
        }}>
          <Typography variant="h4" align="center" gutterBottom sx={{ mb: 6 }}>
            記録がもたらす価値
          </Typography>
          <Timeline 
            position="right"
            sx={{
              [`& .MuiTimelineItem-root:before`]: {
                flex: 0,
                padding: 0
              },
              pl: { xs: 0, sm: 2, md: 4 }
            }}
          >
            {benefits.map((benefit, index) => (
              <TimelineItem key={index}>
                <TimelineSeparator>
                  <TimelineDot sx={{ 
                    bgcolor: alpha(theme.palette.primary.main, 0.1),
                    border: `2px solid ${theme.palette.primary.main}`,
                    color: theme.palette.primary.main,
                    p: 1,
                    boxShadow: `0 0 20px ${alpha(theme.palette.primary.main, 0.3)}`,
                  }}>
                    {benefit.icon}
                  </TimelineDot>
                  {index < benefits.length - 1 && (
                    <TimelineConnector sx={{ 
                      background: `linear-gradient(to bottom, ${theme.palette.primary.main}, transparent)`,
                      width: '2px',
                      opacity: 0.3
                    }} />
                  )}
                </TimelineSeparator>
                <TimelineContent sx={{ 
                  py: '12px',
                  px: 2,
                  ml: { xs: 1, sm: 2 }
                }}>
                  <Paper 
                    elevation={0}
                    sx={{ 
                      p: 3,
                      backgroundColor: alpha(theme.palette.background.paper, 0.6),
                      borderRadius: '16px',
                      backdropFilter: 'blur(10px)',
                      border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                      transition: 'transform 0.2s',
                      '&:hover': {
                        transform: 'scale(1.02)',
                      }
                    }}
                  >
                    <Typography 
                      variant="h6" 
                      component="h3" 
                      sx={{ 
                        mb: 1,
                        background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                        backgroundClip: 'text',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        fontWeight: 'bold'
                      }}
                    >
                      {benefit.title}
                    </Typography>
                    <Typography color="text.secondary" sx={{ lineHeight: 1.7 }}>
                      {benefit.description}
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </Box>

        <Paper 
          elevation={0}
          sx={{ 
            p: 6,
            mt: 8,
            textAlign: 'center',
            backgroundColor: alpha(theme.palette.background.paper, 0.4),
            borderRadius: '16px',
            backdropFilter: 'blur(10px)',
            border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`
          }}
        >
          <Typography variant="h4" gutterBottom>
            さあ、あなたのゲームライフを記録しませんか？
          </Typography>
          <Typography variant="h6" sx={{ color: 'text.secondary', mb: 4 }}>
            素晴らしいゲーム体験は、記録されることで、より一層輝きを増すのです。
          </Typography>
          <img src="/logo192.png" alt="PULSE" style={{ width: '48px', opacity: 0.9 }} />
        </Paper>
      </Container>
    </>
  );
};

export default WhyRecordPage;