import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Typography, Grid, TextField, Button, Box, Pagination, FormControl, InputLabel, Select, MenuItem, Chip, Autocomplete, IconButton, useMediaQuery, Theme as MuiTheme } from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';
import { searchGames, getGenres, getPlatforms, getBasicGameStatuses, getThemes } from '../services/api';
import { Game, Genre, Platform, Theme as GameTheme } from '../types/Game';
import LoadingPlaceholder from '../components/LoadingPlaceholder';
import { SelectChangeEvent } from '@mui/material/Select';
import GameCard from '../components/GameCard';

// SearchFilters インターフェースを更新
interface SearchFilters {
  genres?: number[];
  platforms?: number[];
  themes?: number[];
  year?: number | '';
  order?: string;
}

const GAMES_PER_PAGE = 30;
const CURRENT_YEAR = new Date().getFullYear();
const YEAR_RANGE = Array.from({ length: CURRENT_YEAR - 1970 + 1 }, (_, i) => CURRENT_YEAR - i);

const SearchPage: React.FC = () => {
  const [searchResults, setSearchResults] = useState<Game[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const [initialLoad, setInitialLoad] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [genres, setGenres] = useState<Genre[]>([]);
  const [platforms, setPlatforms] = useState<Platform[]>([]);
  const [selectedGenres, setSelectedGenres] = useState<number[]>([]);
  const [selectedPlatforms, setSelectedPlatforms] = useState<number[]>([]);
  const [selectedYear, setSelectedYear] = useState<number | ''>('');
  const [hasSearched, setHasSearched] = useState(false);
  const [order, setOrder] = useState<string>('');
  const [totalResults, setTotalResults] = useState(0);  // 追加: 総検索結果数
  const [gameStatuses, setGameStatuses] = useState<{ [key: number]: number | null }>({});
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [themes, setThemes] = useState<GameTheme[]>([]);
  const [selectedThemes, setSelectedThemes] = useState<number[]>([]);

  const isMobile = useMediaQuery((theme: MuiTheme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const query = params.get('q') || '';
    const pageParam = params.get('page');
    const genresParam = params.get('genres');
    const platformsParam = params.get('platforms');
    const yearParam = params.get('year');
    const orderParam = params.get('order');
    const themesParam = params.get('themes');
    
    setSearchQuery(query);
    const currentPage = pageParam ? parseInt(pageParam, 10) : 1;
    setPage(currentPage);
    const genres = genresParam ? genresParam.split(',').map(Number) : [];
    const platforms = platformsParam ? platformsParam.split(',').map(Number) : [];
    const year = yearParam ? parseInt(yearParam, 10) : '';
    const order = orderParam || '';
    const themes = themesParam ? themesParam.split(',').map(Number) : [];
    setSelectedGenres(genres);
    setSelectedPlatforms(platforms);
    setSelectedYear(year);
    setOrder(order);
    setSelectedThemes(themes);

    if (query || genres.length > 0 || platforms.length > 0 || themes.length > 0 || year || order) {
      setLoading(true);
      setInitialLoad(false);
      setHasSearched(true);

      const filters: SearchFilters = {
        genres,
        platforms,
        themes,
        year,
        order: order === '' ? undefined : order
      };
      
      searchGames(query, currentPage, filters)
        .then((data) => {
          setSearchResults(data.results);
          setTotalPages(Math.ceil(data.count / GAMES_PER_PAGE));
          setTotalResults(data.count);
        })
        .catch((error) => {
          console.error('検索中にエラーが発生しました:', error);
          setSearchResults([]);
          setTotalPages(0);
          setTotalResults(0);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setInitialLoad(false);
      setSearchResults([]);
      setTotalPages(0);
      setHasSearched(false);
    }
  }, [location.search]);

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const [genresData, platformsData, themesData] = await Promise.all([
          getGenres(),
          getPlatforms(),
          getThemes()
        ]);
        setGenres(genresData);
        setPlatforms(platformsData);
        setThemes(themesData);
      } catch (error) {
        console.error('フィルターの取得に失敗しました:', error);
        setGenres([]);
        setPlatforms([]);
        setThemes([]);
      }
    };
    fetchFilters();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);
  }, []);

  useEffect(() => {
    if (isLoggedIn && searchResults.length > 0) {
      const gameIds = searchResults.map(game => game.game_id);
      getBasicGameStatuses(gameIds).then(statuses => {
        setGameStatuses(statuses);
      });
    }
  }, [searchResults, isLoggedIn]);

  const handleGenreChange = (event: SelectChangeEvent<number[]>) => {
    setSelectedGenres(event.target.value as number[]);
  };

  const handlePlatformChange = (event: SelectChangeEvent<number[]>) => {
    setSelectedPlatforms(event.target.value as number[]);
  };

  const handleYearChange = (event: SelectChangeEvent<number | ''>) => {
    setSelectedYear(event.target.value as number | '');
  };

  const createSearchParams = (params: {
    q: string,
    page: string,
    genres: string,
    platforms: string,
    themes: string,
    year: string,
    order: string
  }) => {
    const searchParams = new URLSearchParams();
    
    if (params.q) searchParams.set('q', params.q);
    if (params.page !== '1') searchParams.set('page', params.page);
    if (params.genres) searchParams.set('genres', params.genres);
    if (params.platforms) searchParams.set('platforms', params.platforms);
    if (params.themes) searchParams.set('themes', params.themes);
    if (params.year) searchParams.set('year', params.year);
    if (params.order) searchParams.set('order', params.order);
    
    return searchParams;
  };

  const handleSearchSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const params = createSearchParams({
      q: searchQuery,
      page: '1',
      genres: selectedGenres.join(','),
      platforms: selectedPlatforms.join(','),
      themes: selectedThemes.join(','),
      year: selectedYear.toString(),
      order: order,
    });
    navigate(`/search?${params.toString()}`);
  };

  const handleOrderChange = (newOrder: string) => {
    setOrder(newOrder);
    const params = createSearchParams({
      q: searchQuery,
      page: '1',
      genres: selectedGenres.join(','),
      platforms: selectedPlatforms.join(','),
      themes: selectedThemes.join(','),
      year: selectedYear.toString(),
      order: newOrder,
    });
    navigate(`/search?${params.toString()}`);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    const params = createSearchParams({
      q: searchQuery,
      page: value.toString(),
      genres: selectedGenres.join(','),
      platforms: selectedPlatforms.join(','),
      themes: selectedThemes.join(','),
      year: selectedYear.toString(),
      order: order,
    });
    navigate(`/search?${params.toString()}`);
    window.scrollTo(0, 0);
  };

  const paginatedResults = searchResults.slice((page - 1) * GAMES_PER_PAGE, page * GAMES_PER_PAGE);

  return (
    <>
      <Typography variant="h4" component="h1" gutterBottom>
        ゲーム検索
      </Typography>
      <form onSubmit={handleSearchSubmit}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <TextField
              size="small"
              fullWidth
              variant="outlined"
              label="ゲーム名を入力"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              size="small"
              multiple
              options={[...genres, ...themes]}
              getOptionLabel={(option: Genre | GameTheme) => option.name}
              groupBy={(option: Genre | GameTheme) => 'genre_id' in option ? 'ジャンル' : 'テーマ'}
              value={[
                ...genres.filter((genre) => selectedGenres.includes(genre.genre_id)),
                ...themes.filter((theme) => selectedThemes.includes(theme.theme_id))
              ]}
              onChange={(event, newValue) => {
                const newGenres = newValue
                  .filter((item): item is Genre => 'genre_id' in item)
                  .map(item => item.genre_id);
                const newThemes = newValue
                  .filter((item): item is GameTheme => 'theme_id' in item)
                  .map(item => item.theme_id);
                setSelectedGenres(newGenres);
                setSelectedThemes(newThemes);
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="ジャンル・テーマ" placeholder="選択してください" />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option: Genre | GameTheme, index) => (
                  <Chip size="small" label={option.name} {...getTagProps({ index })} />
                ))
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              size="small"
              multiple
              options={platforms}
              getOptionLabel={(option) => option.name}
              value={platforms.filter((platform) => selectedPlatforms.includes(platform.platform_id))}
              onChange={(event, newValue) => {
                setSelectedPlatforms(newValue.map((platform) => platform.platform_id));
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="プラットフォーム" placeholder="プラットフォームを選択" />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip size="small" label={option.name} {...getTagProps({ index })} />
                ))
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel>リリース年</InputLabel>
              <Select
                value={selectedYear}
                onChange={handleYearChange}
              >
                <MenuItem value="">
                  <em>指定なし</em>
                </MenuItem>
                {YEAR_RANGE.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              検索
            </Button>
          </Grid>
        </Grid>
      </form>

      {hasSearched && (
        <Box sx={{ 
          mt: 4, 
          mb: 2, 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          flexWrap: 'wrap',  // 追加: 小さい画面でも折り返すように
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: { xs: 0, sm: 0 } }}>
            <Typography variant="body1" color="text.secondary">
              {totalResults} 件の検索結果
            </Typography>
          </Box>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            border: 'none',
            borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
            padding: '4px 8px',
          }}>
            <SortIcon sx={{ mr: 1, color: 'action.active', fontSize: '1rem' }} />
            <Select
              value={order}
              onChange={(e) => handleOrderChange(e.target.value as string)}
              displayEmpty
              variant="standard"
              sx={{ 
                '&:before': { borderBottom: 'none' },
                '&:after': { borderBottom: 'none' },
                '& .MuiSelect-select': { paddingBottom: 0, fontSize: '0.8rem' }
              }}
            >
              <MenuItem value="">並び替え</MenuItem>
              <MenuItem value="release_date_desc">発売日（新しい順）</MenuItem>
              <MenuItem value="release_date_asc">発売日（古い順）</MenuItem>
              <MenuItem value="popularity_desc">人気度 - Global</MenuItem>
            </Select>
          </Box>
        </Box>
      )}

      <Box sx={{ mt: 2 }}>
        {initialLoad ? (
          <LoadingPlaceholder type="card" count={GAMES_PER_PAGE} />
        ) : loading ? (
          <LoadingPlaceholder type="card" count={GAMES_PER_PAGE} />
        ) : searchResults.length > 0 ? (
          <>
            <Grid container spacing={2}>
              {searchResults.map((game) => (
                <Grid item xs={6} sm={4} md={2.4} key={game.game_id}>
                  <GameCard 
                    game={game} 
                    initialStatus={gameStatuses[game.game_id]} 
                  />
                </Grid>
              ))}
            </Grid>
            {totalPages > 1 && (
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}
              />
            )}
          </>
        ) : hasSearched ? (
          <Typography variant="h6" component="h2" gutterBottom>
            検索結果が見つかりませんでした。英語での検索もお試しください。
          </Typography>
        ) : null}
      </Box>
    </>
  );
};

export default SearchPage;