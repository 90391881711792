import React, { useState, useEffect } from 'react';
import { Typography, Grid, Button, Box, Container } from '@mui/material';
import { Link } from 'react-router-dom';
import { getTrendingNewReleases, getAnticipatedGames, getPopular2024Games, getBasicGameStatuses } from '../services/api';
import GameCard from '../components/GameCard';
import LoadingPlaceholder from '../components/LoadingPlaceholder';
import { Game } from '../types/Game';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import GroupsIcon from '@mui/icons-material/Groups';
import { Helmet } from 'react-helmet-async';
import { useTheme } from '@mui/material/styles';
import { keyframes } from '@mui/system';
import YearlyGamesSection from '../components/YearlyGamesSection';
import { getAllGameStories } from '../services/contents_api';
import { GameStoryListItem } from '../types/content';
import { StoryCard } from '../components/story/StoryCard';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import { StoriesSection } from '../components/story/StoriesSection';
import { SectionTitle } from '../components/common/SectionTitle';

const HomePage: React.FC = () => {
  const [trendingGames, setTrendingGames] = useState<Game[]>([]);
  const [anticipatedGames, setAnticipatedGames] = useState<Game[]>([]);
  const [popular2024Games, setPopular2024Games] = useState<Game[]>([]);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [gameStatuses, setGameStatuses] = useState<{ [key: number]: number | null }>({});
  const [stories, setStories] = useState<GameStoryListItem[]>([]);

  const fadeIn = keyframes`
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  `;

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);

    // ログイン状態の変更を監視
    const handleStorageChange = () => {
      const token = localStorage.getItem('token');
      setIsLoggedIn(!!token);
    };
    window.addEventListener('storage', handleStorageChange);
    window.addEventListener('loginSuccess', handleStorageChange);
    window.addEventListener('logout', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener('loginSuccess', handleStorageChange);
      window.removeEventListener('logout', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    const fetchGames = async () => {
      setLoading(true);
      try {
        const [trendingResponse, anticipatedResponse, popular2024Response, storiesResponse] = await Promise.all([
          getTrendingNewReleases(),
          getAnticipatedGames(),
          getPopular2024Games(),
          getAllGameStories()
        ]);
        setTrendingGames(trendingResponse.data.results);
        setAnticipatedGames(anticipatedResponse.data.results);
        setPopular2024Games(popular2024Response.data.results);
        setStories(storiesResponse.slice(0, 6)); // 最新6件のみ表示
      } catch (error) {
        console.error('データの取得に失敗しました', error);
      } finally {
        setLoading(false);
      }
    };

    fetchGames();
  }, []);

  // ゲームデータ取得後にステータスを一括取得
  useEffect(() => {
    const fetchGameStatuses = async () => {
      if (!isLoggedIn) return;
      
      const allGames = [...trendingGames, ...anticipatedGames, ...popular2024Games];
      if (allGames.length === 0) return;

      const gameIds = allGames.map(game => game.game_id);
      const statuses = await getBasicGameStatuses(gameIds);
      setGameStatuses(statuses);
    };

    fetchGameStatuses();
  }, [trendingGames, anticipatedGames, popular2024Games, isLoggedIn]);

  if (loading) {
    return (
      <>
        <Box
          sx={{
            background: `linear-gradient(135deg, ${theme.palette.primary.main}22 0%, ${theme.palette.background.default} 100%)`,
            minHeight: '85vh',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            mb: 6,
            pt: '64px',
            '&::after': {
              content: '""',
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              height: '300px',
              background: `linear-gradient(to bottom, transparent, ${theme.palette.background.default})`,
              pointerEvents: 'none',
            }
          }}
        >
          <Container maxWidth="lg">
            <Box sx={{ 
              height: '80vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              pt: '64px',
            }}>
              {/* ここにローディングスピナーやシンプルなアニメーションを配置 */}
            </Box>
          </Container>
        </Box>

        <Container maxWidth="lg" sx={{ px: { xs: 1.5, sm: 3 } }}>
          <SectionTitle icon={<WhatshotIcon />} text="話題の新作" />
          <LoadingPlaceholder type="card" count={10} />

          <SectionTitle icon={<NewReleasesIcon />} text="注目の期待作" />
          <LoadingPlaceholder type="card" count={10} />

          <SectionTitle icon={<GroupsIcon />} text="人気のゲーム（2024年）" />
          <LoadingPlaceholder type="card" count={10} />

          <SectionTitle icon={<AutoStoriesIcon />} text="最新のストーリー" />
          <StoriesSection stories={[]} loading={true} />

          <YearlyGamesSection loading={true} />
        </Container>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>PULSE - ゲーム体験を記録できる究極のゲームライブラリ</title>
        <meta 
          name="description" 
          content="国内最大級のゲームデータベースで、あなたのゲーム体験を記録・管理。プレイ履歴の記録、進行中のゲーム管理、気になるタイトルの保存まで。詳細なステータス管理とレビュー・スコアリングで、あなただけのゲームライブラリを作成できます。"
        />
        <meta 
          property="og:title" 
          content="PULSE - ゲーム体験を記録できる究極のゲームライブラリ" 
        />
        <meta 
          property="og:description" 
          content="国内最大級のゲームデータベースで、あなたのゲーム体験を記録・管理。プレイ履歴の記録、進行中のゲーム管理、気になるタイトルの保存まで。詳細なステータス管理とレビュー・スコアリングで、あなただけのゲームライブラリを作成できます。" 
        />
      </Helmet>

      <Box
        sx={{
          background: `linear-gradient(135deg, ${theme.palette.primary.main}22 0%, ${theme.palette.background.default} 100%)`,
          minHeight: '80vh',
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          mb: 4,
          pt: '64px',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: `radial-gradient(circle at 30% 40%, ${theme.palette.primary.main}05 0%, transparent 60%),
                        radial-gradient(circle at 70% 60%, ${theme.palette.secondary.main}08 0%, transparent 60%)`,
            pointerEvents: 'none',
          },
          '&::after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: '300px',
            background: `linear-gradient(to bottom, transparent, ${theme.palette.background.default})`,
            pointerEvents: 'none',
          }
        }}
      >
        <Container maxWidth="lg">
          <Box sx={{ 
            maxWidth: '1280px', 
            color: 'white', 
            position: 'relative', 
            zIndex: 1,
            pl: { xs: 0, sm: 2 },
            textAlign: { xs: 'left', sm: 'center' }
          }}>
            <Typography
              variant="h1"
              component="h1"
              sx={{
                fontWeight: 'bold',
                mb: 3,
                fontSize: { xs: '2.5rem', sm: '3.2rem', md: '4rem' },
                background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                textShadow: `0 0 20px ${theme.palette.primary.main}22`,
                '& > span': {
                  display: { xs: 'block', sm: 'inline' }, // スマホのみ改行
                },
                '& > span:last-child': {
                  display: 'block' // 「もっと面白い」は常に改行
                },
                animation: `${fadeIn} 1s ease-out`,
              }}
            >
              <span>ゲームは、</span>
              <span>記録すると</span>
              <span>もっと面白い。</span>
            </Typography>
            <Typography
              variant="h5"
              sx={{
                mb: 4,
                opacity: 0.9,
                fontSize: { xs: '0.8rem', sm: '1.3rem', md: '1.5rem' },
                color: 'rgba(255,255,255,0.9)',
                maxWidth: '1000px',
                mx: 'auto',
                lineHeight: 1.6,
                animation: `${fadeIn} 1s ease-out 0.3s backwards`,
              }}
            >
              18万本超の国内最大級データベースで、あなたのゲーム体験を記録・管理し、究極のゲームライブラリをつくろう
            </Typography>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: { xs: 'column', sm: 'row' },
              gap: 2,
              width: {
                xs: '100%',
                sm: 'auto'
              },
              justifyContent: { xs: 'flex-start', sm: 'center' },
              animation: `${fadeIn} 1s ease-out 0.6s backwards`,
            }}>
              <Button
                component={Link}
                to={isLoggedIn ? "/profile" : "/login"}
                variant="contained"
                size="large"
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.background.default,
                  fontWeight: 'bold',
                  px: { xs: 2, sm: 4 },
                  '&:hover': {
                    backgroundColor: theme.palette.primary.dark,
                  }
                }}
              >
                {isLoggedIn ? "ゲームライブラリへ" : "今すぐライブラリを開設"}
              </Button>
              <Button
                component={Link}
                to="/search"
                variant="outlined"
                size="large"
                sx={{
                  borderColor: theme.palette.primary.main,
                  color: theme.palette.primary.main,
                  backgroundColor: `${theme.palette.primary.main}11`,
                  px: { xs: 2, sm: 4 },
                  '&:hover': {
                    borderColor: theme.palette.primary.light,
                    backgroundColor: `${theme.palette.primary.main}21`
                  }
                }}
              >
                PULSEでゲームを探す
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>

      <Container maxWidth="lg" sx={{ px: { xs: 1.5, sm: 3 } }}>
        <SectionTitle icon={<WhatshotIcon />} text="話題の新作" />
        <Grid container spacing={1.5}>
          {trendingGames.map((game) => (
            <Grid item xs={6} sm={4} md={2.4} key={game.game_id}>
              <GameCard 
                game={game} 
                initialStatus={gameStatuses[game.game_id]}
              />
            </Grid>
          ))}
        </Grid>

        <SectionTitle icon={<NewReleasesIcon />} text="注目の期待作" />
        <Grid container spacing={1.5}>
          {anticipatedGames.map((game) => (
            <Grid item xs={6} sm={4} md={2.4} key={game.game_id}>
              <GameCard 
                game={game} 
                initialStatus={gameStatuses[game.game_id]}
              />
            </Grid>
          ))}
        </Grid>

        <SectionTitle icon={<GroupsIcon />} text="人気のゲーム（2024年）" />
        <Grid container spacing={1.5}>
          {popular2024Games.map((game) => (
            <Grid item xs={6} sm={4} md={2.4} key={game.game_id}>
              <GameCard 
                game={game} 
                initialStatus={gameStatuses[game.game_id]}
              />
            </Grid>
          ))}
        </Grid>

        <SectionTitle icon={<AutoStoriesIcon />} text="最新のストーリー" />
        <StoriesSection stories={stories} loading={loading} />

        <YearlyGamesSection loading={loading} />
      </Container>
    </>
  );
};

export default HomePage;
