import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, TextField, Button, Divider, Alert } from '@mui/material';
import { getGameStory, updateGameStory } from '../../../services/contents_api';
import { getCurrentUserId } from '../../../services/auth';
import { GameStory, ContentBlock } from '../../../types/content';
import LoadingPlaceholder from '../../../components/LoadingPlaceholder';
import StoryEditor from '../../../components/story/StoryEditor';

const EditGameStoryPage: React.FC = () => {
  const { gameId, storyId } = useParams<{ gameId: string; storyId: string }>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [title, setTitle] = useState('');
  const [heroImageUrl, setHeroImageUrl] = useState('');
  const [thumbnailUrl, setThumbnailUrl] = useState('');
  const [blocks, setBlocks] = useState<ContentBlock[]>([]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      setError('ログインが必要です。');
      return;
    }

    const fetchStoryAndCheckAuth = async () => {
      try {
        const [story, currentUserId] = await Promise.all([
          getGameStory(Number(storyId)),
          getCurrentUserId()
        ]);

        console.log('Story data:', story);
        console.log('Current user ID:', currentUserId);

        if (story.author !== currentUserId) {
          console.log('Author:', story.author, 'Current user:', currentUserId);
          setError('このストーリーを編集する権限がありません。');
          return;
        }

        setTitle(story.title);
        setHeroImageUrl(story.hero_image_url);
        setThumbnailUrl(story.thumbnail_url);
        setBlocks(story.content.blocks);
      } catch (error) {
        console.error('ストーリーの取得に失敗しました', error);
        setError('ストーリーの取得に失敗しました。');
      } finally {
        setLoading(false);
      }
    };

    fetchStoryAndCheckAuth();
  }, [storyId]);

  // エラー表示
  if (error) {
    return (
      <Box sx={{ maxWidth: 800, mx: 'auto', mt: 4 }}>
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
        <Button onClick={() => navigate(`/game/${gameId}/stories`)}>
          ストーリー一覧に戻る
        </Button>
      </Box>
    );
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const storyData: Partial<GameStory> = {
        title,
        hero_image_url: heroImageUrl,
        thumbnail_url: thumbnailUrl,
        content: { blocks }
      };
      await updateGameStory(Number(storyId), storyData);
      navigate(`/game/${gameId}/stories/${storyId}`);
    } catch (error) {
      console.error('ストーリーの更新に失敗しました', error);
    }
  };

  if (loading) {
    return <LoadingPlaceholder type="detail" count={1} />;
  }

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 800, mx: 'auto' }}>
      <Typography variant="h4" gutterBottom>ストーリー編集</Typography>
      
      <TextField
        fullWidth
        label="タイトル"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        margin="normal"
        required
      />

      <TextField
        fullWidth
        label="ヒーロー画像URL"
        value={heroImageUrl}
        onChange={(e) => setHeroImageUrl(e.target.value)}
        margin="normal"
        required
      />

      <TextField
        fullWidth
        label="サムネイル画像URL"
        value={thumbnailUrl}
        onChange={(e) => setThumbnailUrl(e.target.value)}
        margin="normal"
        required
      />

      <Divider sx={{ my: 3 }} />
      
      <Typography variant="h6" gutterBottom>
        ストーリー本文
      </Typography>

      <StoryEditor blocks={blocks} onChange={setBlocks} />

      <Box sx={{ mt: 3 }}>
        <Button type="submit" variant="contained" color="primary">
          更新
        </Button>
      </Box>
    </Box>
  );
};

export default EditGameStoryPage;