import React, { useState, useEffect, memo } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Typography, Grid, Chip, Box, Card, CardContent, CardMedia, Button, Skeleton, Container, IconButton } from '@mui/material';
import { getGameDetails, getUserGameStatus, getUserProfile, getUserBirthDate } from '../services/api';
import GameStatusReview from '../components/GameStatusReview';
import SubStatusButton from '../components/SubStatusButton';
import { UserProfile } from '../types/UserProfile';
import { AxiosResponse } from 'axios';
import { styled } from '@mui/material/styles';
import ReviewSection from '../components/review/ReviewSection';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import WikipediaViewer from '../components/WikipediaViewer';
import { Helmet } from 'react-helmet-async';

interface Genre {
  genre_id: number;
  name: string;
}

interface Platform {
  platform_id: number;
  name: string;
}

interface Franchise {
  franchise_id: number;
  name: string;
  japanese_name: string | null;
}

interface Collection {
  collection_id: number;
  name: string;
  japanese_name: string | null;
}

interface Game {
  game_id: number;
  name: string;
  japanese_name: string | null;
  summary: string | null;
  first_release_date: string;
  rating: number | null;
  genres: Genre[];
  platforms: Platform[];
  franchises: Franchise[];
  collections: Collection[];
  cover?: {
    url: string;
    width: number;
    height: number;
  };
  cover_hd?: {
    url: string;
    width: number;
    height: number;
  };
  game_engines: GameEngine[];
  themes: Theme[];
  parent_game_id: number | null;
  companies: Company[];
  slug?: string;
}

interface GameEngine {
  game_engine_id: number;
  name: string;
}

interface Theme {
  theme_id: number;
  name: string;
}

interface Company {
  company_id: number;
  name: string;
  japanese_name: string | null;
  role?: string;
}

interface InfoSectionProps {
  title: string;
  items: Array<{ 
    [key: string]: any, 
    name: string, 
    japanese_name?: string | null, 
    role?: string 
  }>;
  linkPrefix: string;
  idKey: string | ((item: any) => string);
  showRole?: boolean;
  customLink?: boolean;
}

const InfoSection: React.FC<InfoSectionProps> = ({ title, items, linkPrefix, idKey, showRole = false, customLink = false }) => (
  <Box sx={{ mb: 2 }}>
    <Typography variant="subtitle2" gutterBottom sx={{ fontWeight: 'bold' }}>
      {title}:
    </Typography>
    {items && items.length > 0 ? (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
        {items.map((item) => {
          const id = typeof idKey === 'function' ? idKey(item) : item[idKey];
          const linkType = item.genre_id ? 'genre' : 'theme';
          const link = customLink ? `/games/${linkType}/${id}` : `${linkPrefix}${id}`;
          const displayName = item.japanese_name || item.name;
          return (
            <Chip
              key={`${id}-${item.role || ''}`}
              label={`${displayName}${showRole && item.role && item.role !== '開発' ? ` (${item.role})` : ''}`}
              component={Link}
              to={link}
              clickable
              size="small"
              sx={{ fontSize: '0.7rem' }}
            />
          );
        })}
      </Box>
    ) : (
      <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>情報なし</Typography>
    )}
  </Box>
);

const FullWidthGlassSection = styled('div')({
  position: 'relative',
  width: '100vw',
  left: '50%',
  right: '50%',
  marginLeft: '-50vw',
  marginRight: '-50vw',
  overflow: 'hidden',
});

const BackgroundImage = styled('div')<{ image: string }>(({ image }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundImage: `url(${image})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  filter: 'blur(35px)',
  opacity: 0.4,
  zIndex: -1,
}));

const GlassOverlay = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(18, 18, 18, 0.7)',
  backdropFilter: 'blur(10px)',
  zIndex: -1,
});

const ContentWrapper = styled(Container)({
  position: 'relative',
  zIndex: 1,
});

const NormalSection = styled('div')({
  backgroundColor: '#121212',
  padding: '2rem 0',
});

const GameDetailsPage: React.FC = () => {
  const { game_id } = useParams<{ game_id: string }>();
  const [game, setGame] = useState<Game | null>(null);
  const [parentGame, setParentGame] = useState<Game | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [gameStatus, setGameStatus] = useState<number | null>(null);
  const [gameScore, setGameScore] = useState<number | null>(null);
  const [gameReviewText, setGameReviewText] = useState<string | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(undefined);
  const [gamePlayStartDate, setGamePlayStartDate] = useState<string | null>(null);
  const [gamePlayEndDate, setGamePlayEndDate] = useState<string | null>(null);
  const [gamePlayTime, setGamePlayTime] = useState<number | null>(null);
  const [gameSubStatus, setGameSubStatus] = useState<number[]>([]);
  const [gameScoreTags, setGameScoreTags] = useState<number[]>([]);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [wikiSummary, setWikiSummary] = useState<string | null>(null);
  const [wikiOpen, setWikiOpen] = useState(false);
  const [hasWikiData, setHasWikiData] = useState(false);
  const [totalReviews, setTotalReviews] = useState(0);

  useEffect(() => {
    const determineAuthentication = () => {
      const token = localStorage.getItem('token');
      const authenticated = !!token;
      setIsAuthenticated(authenticated);
      return authenticated;
    };

    const fetchGameDetails = async () => {
      setLoading(true);
      setGame(null);
      setParentGame(null);
      setGameStatus(null);
      setGameScore(null);
      setGameReviewText(null);
      setGamePlayStartDate(null);
      setGamePlayEndDate(null);
      setGamePlayTime(null);
      setGameSubStatus([]);
      setGameScoreTags([]);

      try {
        if (game_id) {
          const data = await getGameDetails(parseInt(game_id, 10));
          setGame(data);
          
          if (data.parent_game_id !== null) {
            const parentData = await getGameDetails(data.parent_game_id.toString());
            setParentGame(parentData);
          }

          const authenticated = determineAuthentication();

          if (authenticated) {
            const { status, score, reviewText, playStartDate, playEndDate, playTime, subStatus, scoreTags } = await getUserGameStatus(parseInt(game_id, 10));
            setGameStatus(status);
            setGameScore(score);
            setGameReviewText(reviewText);
            setGamePlayStartDate(playStartDate);
            setGamePlayEndDate(playEndDate);
            setGamePlayTime(playTime);
            setGameSubStatus(subStatus || []);
            setGameScoreTags(scoreTags || []);

            const birthDate = await getUserBirthDate();
            setUserProfile(birthDate ? { birth_date: birthDate } as UserProfile : null);
          }
        }
      } catch (error) {
        console.error('ゲーム詳細の取得に失敗しました', error);
      } finally {
        setLoading(false);
      }
    };

    fetchGameDetails();

    window.scrollTo(0, 0);
  }, [game_id]);

  useEffect(() => {
    if (game) {
      const fetchWikiSummary = async () => {
        try {
          const response = await fetch(`https://ja.wikipedia.org/api/rest_v1/page/summary/${encodeURIComponent(game.japanese_name || game.name)}`);
          const data = await response.json();
          if (data.extract) {
            setWikiSummary(data.extract);
            setHasWikiData(true);
          } else {
            setHasWikiData(false);
          }
        } catch (error) {
          console.error('Wikipedia APIからのデータ取得に失敗しました:', error);
          setHasWikiData(false);
        }
      };

      fetchWikiSummary();
    }
  }, [game]);

  const formatReleaseDateInfo = (releaseDate: string) => {
    const release = new Date(releaseDate);
    const now = new Date();
    const isFutureRelease = release > now;

    if (isFutureRelease) {
      return release.toLocaleDateString('ja-JP', { 
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit' 
      }).replace(/\//g, '/');
    }

    const diffTime = now.getTime() - release.getTime();
    const diffYears = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 365.25));
    const diffMonths = Math.floor((diffTime % (1000 * 60 * 60 * 24 * 365.25)) / (1000 * 60 * 60 * 24 * 30.44));
    const diffDays = Math.floor((diffTime % (1000 * 60 * 60 * 24 * 30.44)) / (1000 * 60 * 60 * 24));

    let ageString = '';
    if (diffYears >= 1) {
      const birthYear = userProfile?.birth_date ? new Date(userProfile.birth_date) : null;
      if (birthYear) {
        let ageAtRelease = release.getFullYear() - birthYear.getFullYear();
        if (release < new Date(release.getFullYear(), birthYear.getMonth(), birthYear.getDate())) {
          ageAtRelease--;
        }
        ageString = `｜${ageAtRelease}歳のとき`;
      }
    }

    let timeAgo = '';
    if (diffYears > 0) {
      timeAgo += `${diffYears}年`;
    }
    if (diffMonths > 0) {
      timeAgo += `${diffMonths}ヶ月`;
    }
    if (timeAgo === '' && diffDays > 0) {
      timeAgo = `${diffDays}日`;
    }
    if (timeAgo === '') {
      timeAgo = '0日';
    }

    const dateString = release.toLocaleDateString('ja-JP', { 
      year: 'numeric', 
      month: '2-digit', 
      day: '2-digit' 
    }).replace(/\//g, '/');

    return `${dateString}（${timeAgo}前${ageString}）`;
  };

  if (loading) {
    return (
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Skeleton variant="rectangular" width="100%" height={450} />
          <Box sx={{ pt: 1.5 }}>
            <Skeleton width="100%" height={40} />
            <Skeleton width="60%" height={30} />
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box sx={{ width: '100%' }}>
            <Skeleton variant="rectangular" width="100%" height={40} />
            <Box sx={{ pt: 1.5 }}>
              <Skeleton width="60%" height={40} />
              <Skeleton />
              <Skeleton />
              <Skeleton width="80%" />
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  }

  if (!game) {
    return <Typography>ゲーム情報が見つかりません。</Typography>;
  }

  const handleStatusChange = (
    newStatus: number | null,
    newScore: number | null,
    newReviewText: string | null,
    newPlayStartDate: string | null,
    newPlayEndDate: string | null,
    newPlayTime: number | null,
    newSubStatus: number[],
    newScoreTags: number[]
  ) => {
    setGameStatus(newStatus);
    setGameScore(newScore);
    setGameReviewText(newReviewText);
    setGamePlayStartDate(newPlayStartDate);
    setGamePlayEndDate(newPlayEndDate);
    setGamePlayTime(newPlayTime);
    setGameSubStatus(newSubStatus);
    setGameScoreTags(newScoreTags);
  };

  const processCompanies = (companies: Company[]) => {
    const roleMap: { [key: string]: Company[] } = {
      '開発': [],
      '協力': [],
      '移植': [],
      '発売': []
    };

    companies.forEach(company => {
      if (company.role) {
        const roles = company.role.split(', ');
        roles.forEach(role => {
          if (roleMap[role]) {
            roleMap[role].push({...company, role});
          } else {
            roleMap['その他'] = roleMap['その他'] || [];
            roleMap['その他'].push({...company, role});
          }
        });
      }
    });

    return roleMap;
  };

  const companyRoles = processCompanies(game.companies);

  const developers = [...companyRoles['開発'], ...companyRoles['協力'], ...companyRoles['移植']];
  const publishers = companyRoles['発売'];

  const genresAndThemes = [...game.genres, ...game.themes];

  return (
    <>
      <Helmet>
        <title>
          {game?.japanese_name 
            ? `${game.japanese_name} / ${game.name}${game.first_release_date ? ` (${new Date(game.first_release_date).getFullYear()})` : ''} のゲーム情報 | PULSE`
            : `${game?.name}${game?.first_release_date ? ` (${new Date(game.first_release_date).getFullYear()})` : ''} のゲーム情報 | PULSE ゲームデータベース`
          }
        </title>
      </Helmet>
      <FullWidthGlassSection>
        {game && game.cover_hd && (
          <BackgroundImage image={game.cover_hd.url} />
        )}
        <GlassOverlay />
        <ContentWrapper maxWidth="lg" sx={{ px: 1.5, py: 4 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <img
                src={game.cover_hd ? game.cover_hd.url : '/image/no_image.png'}
                alt={game.japanese_name || game.name}
                style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
              />
              <Box sx={{ 
                display: 'flex', 
                flexWrap: 'wrap',
                gap: 1,
                '& > *': {
                  flexShrink: 0  // 子要素の縮小を防ぐ
                }
              }}>
                <GameStatusReview
                  gameId={game.game_id}
                  initialStatus={gameStatus}
                  initialScore={gameScore}
                  initialReviewText={gameReviewText}
                  initialPlayStartDate={gamePlayStartDate}
                  initialPlayEndDate={gamePlayEndDate}
                  initialPlayTime={gamePlayTime}
                  initialSubStatus={gameSubStatus}
                  initialScoreTags={gameScoreTags}
                  onStatusChange={handleStatusChange}
                />
                {gameStatus && (
                  <SubStatusButton
                    gameId={game.game_id}
                    status={gameStatus}
                    initialSubStatus={gameSubStatus}
                    onSubStatusChange={(newSubStatus) => setGameSubStatus(newSubStatus)}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              {game.japanese_name ? (
                <>
                  <Typography variant="h4" component="h1">
                    {game.japanese_name}
                    {game.first_release_date && (
                      <Typography component="span" color="text.secondary" sx={{ ml: 0, fontSize: '1em' }}>
                        （{new Date(game.first_release_date).getFullYear()}）
                      </Typography>
                    )}
                  </Typography>
                  <Typography 
                    variant="h6" 
                    component="h2" 
                    gutterBottom 
                    sx={{ 
                      color: 'text.secondary',
                      fontSize: '1.15rem',
                      mb: 2
                    }}
                  >
                    {game.name}
                  </Typography>
                </>
              ) : (
                <Typography variant="h4" component="h1" gutterBottom>
                  {game.name}
                  {game.first_release_date && (
                    <Typography component="span" color="text.secondary" sx={{ ml: 0, fontSize: '1em' }}>
                      （{new Date(game.first_release_date).getFullYear()}）
                    </Typography>
                  )}
                </Typography>
              )}
              
              {/* プラットフォーム情報 */}
              <Box sx={{ mb: 2 }}>
                {game.platforms && game.platforms.length > 0 ? (
                  game.platforms.map((platform) => (
                    <Chip
                      key={platform.platform_id}
                      label={platform.name}
                      component={Link}
                      to={`/games/platform/${platform.platform_id}`}
                      clickable
                      variant="outlined"
                      sx={{ mr: 1, mb: 1 }}
                    />
                  ))
                ) : (
                  <Typography variant="body2">プラットフォーム情報なし</Typography>
                )}
              </Box>

              {/* Twitch、Amazon、YouTubeのリンク */}
              <Box sx={{ mb: 3, display: 'flex', gap: 1 }}>
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<img src="/icons/service/twitch.svg" alt="Twitch" style={{ width: 18, height: 18, filter: 'brightness(0) invert(1)' }} />}
                  component="a"
                  href={`https://www.twitch.tv/directory/category/${encodeURIComponent((game.slug || game.name).replace(/--\d+$/, ''))}/videos/all`}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    textTransform: 'none',
                    backgroundColor: '#9147ff',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#7d2df0',
                    },
                  }}
                >
                  Twitch
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<img src="/icons/service/amazon.svg" alt="Amazon" style={{ width: 18, height: 18 }} />}
                  component="a"
                  href={`https://www.amazon.co.jp/s?k=${encodeURIComponent(game.japanese_name || game.name)}&tag=pulse-db-22`}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    textTransform: 'none',
                    backgroundColor: '#ff9900',
                    '&:hover': {
                      backgroundColor: '#e68a00',
                    },
                  }}
                >
                  Amazon
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<img src="/icons/service/youtube.svg" alt="YouTube" style={{ width: 18, height: 18, filter: 'brightness(0) invert(1)' }} />}
                  component="a"
                  href={`https://www.youtube.com/results?search_query=${encodeURIComponent(game.japanese_name || game.name)}+ゲーム`}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    textTransform: 'none',
                    backgroundColor: '#c00000',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#b30000',
                    },
                  }}
                >
                  YouTube
                </Button>
              </Box>

              {parentGame && (
                <Box sx={{ mb: 3, mt: 2, position: 'relative', display: 'inline-block', width: '100%' }}>
                  <Box 
                    sx={{ 
                      position: 'absolute', 
                      top: -8, 
                      right: 8, 
                      backgroundColor: '#1a237e',
                      color: 'white', 
                      padding: '1px 6px', 
                      borderRadius: '4px',
                      fontSize: '0.6rem',
                      fontWeight: 'bold',
                      zIndex: 1,
                    }}
                  >
                    Original
                  </Box>
                  <Card 
                    sx={{ 
                      display: 'flex', 
                      maxWidth: '100%', 
                      width: '100%',
                      borderRadius: '6px',
                      cursor: 'pointer',
                      '&:hover': {
                        boxShadow: 2,
                      },
                    }}
                    onClick={() => navigate(`/game/${parentGame.game_id}`)}
                  >
                    <CardMedia
                      component="img"
                      sx={{ width: 80, height: 112, objectFit: 'cover', borderTopLeftRadius: '6px', borderBottomLeftRadius: '6px' }}
                      image={parentGame.cover ? parentGame.cover.url : '/placeholder.jpg'}
                      alt={parentGame.name}
                    />
                    <CardContent sx={{ flex: '1 0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', pt: 1, pb: 1, px: 2, width: 'calc(100% - 80px)' }}>
                      <Typography 
                        component="div" 
                        variant="subtitle1" 
                        sx={{ 
                          mb: 0.5, 
                          fontSize: { xs: '0.8rem', sm: '0.9rem' },
                          lineHeight: 1.2,
                          height: { xs: '2.4em', sm: 'auto' },
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: { xs: 2, sm: 'none' },
                          WebkitBoxOrient: 'vertical',
                        }}
                      >
                        {parentGame.japanese_name || parentGame.name}
                      </Typography>
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.25 }}>
                        <Typography variant="body2" color="text.secondary" sx={{ fontSize: { xs: '0.65rem', sm: '0.7rem' } }}>
                          {parentGame.first_release_date ? new Date(parentGame.first_release_date).getFullYear() : '発売年不明'}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ 
                          overflow: 'hidden', 
                          textOverflow: 'ellipsis', 
                          whiteSpace: 'nowrap',
                          fontSize: { xs: '0.65rem', sm: '0.7rem' }
                        }}>
                          {parentGame.platforms && parentGame.platforms.length > 0 
                            ? parentGame.platforms.map(platform => platform.name).join(', ') 
                            : 'プラットフォーム不明'}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
              )}
              
              {(game.summary || wikiSummary) && (
                <Box sx={{ position: 'relative', mb: 3 }}>
                  <Typography 
                    variant="body2" 
                    paragraph 
                    sx={{ 
                      color: 'text.secondary', 
                      fontSize: '0.7rem', 
                      pr: hasWikiData ? 4 : 0  // Wikipediaのデータがある場合のみアイコンのスペースを確保
                    }}
                  >
                    {game.summary || wikiSummary}
                  </Typography>
                  {hasWikiData && (
                    <IconButton
                      onClick={() => setWikiOpen(true)}
                      sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        padding: '4px',
                      }}
                    >
                      <AutoStoriesIcon fontSize="small" />
                    </IconButton>
                  )}
                </Box>
              )}
              
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <InfoSection 
                    title="ジャンル・テーマ" 
                    items={genresAndThemes} 
                    linkPrefix="/games/" 
                    idKey={(item: any) => item.genre_id || item.theme_id}
                    customLink={true} 
                  />
                  <InfoSection title="IP" items={game.franchises} linkPrefix="/games/franchise/" idKey="franchise_id" />
                  <InfoSection title="シリーズ" items={game.collections} linkPrefix="/games/collection/" idKey="collection_id" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InfoSection title="デベロッパー" items={developers} linkPrefix="/games/company/" idKey="company_id" showRole={true} />
                  <InfoSection title="パブリッシャー" items={publishers} linkPrefix="/games/company/" idKey="company_id" showRole={false} />
                  <InfoSection title="ゲームエンジン" items={game.game_engines} linkPrefix="/games/game-engine/" idKey="game_engine_id" />
                </Grid>
              </Grid>
              
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2">
                  <strong>発売日:</strong> {game.first_release_date ? formatReleaseDateInfo(game.first_release_date) : '情報なし'}
                </Typography>
                <Typography variant="body2">
                  <strong>評価:</strong> {game.rating ? `${game.rating}/100` : '情報なし'}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </ContentWrapper>
      </FullWidthGlassSection>
      <NormalSection>
          <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              <ReviewSection 
                gameId={parseInt(game_id!, 10)} 
                limit={3} 
                onTotalReviewsChange={setTotalReviews}
              />
              {totalReviews > 3 && (
                <Box sx={{ mt: 2, textAlign: 'right' }}>
                  <Link to={`/game/${game_id}/reviews`}>
                    全てのレビューを見る
                  </Link>
                </Box>
              )}
            </Grid>
          </Grid>
      </NormalSection>
      <WikipediaViewer
        open={wikiOpen}
        onClose={() => setWikiOpen(false)}
        title={game?.japanese_name || game?.name || ''} 
        gameId={game.game_id} // game_idを追加
      />
    </>
  );
};

export default memo(GameDetailsPage);
