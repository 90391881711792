import React from 'react';
import { Dialog as MuiDialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

interface DialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  content: React.ReactNode;
}

const RoundedPaper = styled(Paper)({
  borderRadius: '8px',
});

const Dialog: React.FC<DialogProps> = ({ open, onClose, title, content }) => {
  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperComponent={RoundedPaper}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {typeof content === 'string' ? (
          <Typography>{content}</Typography>
        ) : (
          content
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>閉じる</Button>
      </DialogActions>
    </MuiDialog>
  );
};

export default Dialog;