import React, { useEffect, useState } from 'react';
import { Typography, Paper, Grid, LinearProgress, Tooltip, Box } from '@mui/material';
import { getStatusLimits } from '../../services/api';
import { UserProfile } from '../../types/UserProfile';

interface StatusLimit {
  status: number;
  name: string;
  baseLimit: number;
  extensionLimit: number;
  current: number;
}

interface StatusLimitsDisplayProps {
  profile: UserProfile;
}

const StatusLimitsDisplay: React.FC<StatusLimitsDisplayProps> = ({ profile }) => {
  const [statusLimits, setStatusLimits] = useState<StatusLimit[]>([]);

  useEffect(() => {
    const fetchStatusLimits = async () => {
      try {
        const limits = await getStatusLimits();
        const limitsWithCurrent = limits.map(limit => ({
          ...limit,
          current: profile.status_counts[limit.status - 1] || 0
        }));
        setStatusLimits(limitsWithCurrent);
      } catch (error) {
        console.error('ステータス制限の取得に失敗しました', error);
      }
    };

    fetchStatusLimits();
  }, [profile]);

  return (
    <Box sx={{ width: '100%', maxWidth: 600 }}>
      <Typography variant="h6" gutterBottom>
        ステータスごとの保存数
      </Typography>
      <Paper elevation={0} sx={{ p: 3, mb: 3 }}>
        {statusLimits.map((limit) => {
          const totalLimit = limit.baseLimit + limit.extensionLimit;
          return (
            <Box key={limit.status} sx={{ mb: 3 }}>
              <Typography variant="subtitle1" gutterBottom>
                {limit.name}
              </Typography>
              <Typography variant="body2" gutterBottom>
                {limit.current} / {totalLimit} (基本: {limit.baseLimit} + 拡張: {limit.extensionLimit})
              </Typography>
              <Tooltip title={`${limit.current} / ${totalLimit}`} arrow placement="top">
                <LinearProgress 
                  variant="determinate" 
                  value={(limit.current / totalLimit) * 100} 
                  sx={{ height: 10, borderRadius: 5 }}
                />
              </Tooltip>
            </Box>
          );
        })}
      </Paper>
    </Box>
  );
};

export default StatusLimitsDisplay;
