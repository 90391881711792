import React from 'react';
import { Box, Skeleton, Grid } from '@mui/material';

interface StoryLoadingPlaceholderProps {
  type?: 'detail' | 'list';
}

const StoryLoadingPlaceholder: React.FC<StoryLoadingPlaceholderProps> = ({ type = 'detail' }) => {
  if (type === 'list') {
    return (
      <Grid container spacing={3}>
        {[...Array(6)].map((_, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Box>
              <Skeleton 
                variant="rectangular" 
                width="100%" 
                height={0}
                sx={{ 
                  paddingTop: '66.67%',
                  borderRadius: 3,
                  mb: 2
                }}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <Box sx={{ 
      width: '100%', 
      maxWidth: '680px', 
      mx: 'auto', 
      px: { xs: 2, sm: 3 },
      pt: { xs: 2, sm: 3 } 
    }}>
      {/* ヒーローセクション */}
      <Skeleton 
        variant="rectangular" 
        width="100%" 
        height={0}
        sx={{ 
          paddingTop: '100%', 
          borderRadius: 3,
          mt: { xs: 2, sm: 3 },
          mb: 6 
        }}
      />

      {/* コンテンツブロック */}
      {[...Array(10)].map((_, index) => (
        <Box key={index} sx={{ mb: 4 }}>
          <Skeleton width="40%" height={32} sx={{ mb: 2 }} />
          <Skeleton width="100%" height={30} sx={{ mb: 1 }}/>
          <Skeleton width="100%" height={30} sx={{ mb: 1 }}/>
          <Skeleton width="100%" height={30} sx={{ mb: 2 }}/>
          <Skeleton width="100%" height={30} sx={{ mb: 1 }}/>
          <Skeleton width="100%" height={30} sx={{ mb: 1 }}/>
          <Skeleton width="90%" height={30} sx={{ mb: 1 }}/>
        </Box>
      ))}
    </Box>
  );
};

export default StoryLoadingPlaceholder;