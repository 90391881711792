import React from 'react';
import { Box, Container, Typography, Link, Grid } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { SITE_NAME } from '../constants/site';

const Footer: React.FC = () => {
  return (
    <Box component="footer" sx={{ bgcolor: 'background.paper', py: 6, mt: 8 }}>
      <Container maxWidth="lg">
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'center', 
              flexWrap: 'wrap', 
              gap: { xs: 2, sm: 3 }, 
              mb: 2,
              textAlign: 'center'
            }}>
              <Link
                component={RouterLink}
                to="/privacy-policy"
                color="text.secondary"
                variant="body2"
                sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}
              >
                プライバシーポリシー
              </Link>
              <Link
                component={RouterLink}
                to="/terms-of-service"
                color="text.secondary"
                variant="body2"
                sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}
              >
                利用規約
              </Link>
              <Link
                href="https://dev.plsdb.com/contact"
                color="text.secondary"
                variant="body2"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}
              >
                お問い合わせ
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="text.secondary" align="center">
              {'© '}{new Date().getFullYear()} {' '}
              <Link 
                component={RouterLink} 
                to="/" 
                color="inherit" 
                sx={{ textDecoration: 'none' }}
              >
                {SITE_NAME}
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;