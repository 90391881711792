import React, { useEffect, useState } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { Box, Typography, Button, Grid, } from '@mui/material';
import { getGameStories } from '../../../services/contents_api';
import { GameStoryListItem } from '../../../types/content';
import LoadingPlaceholder from '../../../components/LoadingPlaceholder';
import { StoryCard } from '../../../components/story/StoryCard';
import StoryLoadingPlaceholder from '../../../components/story/StoryLoadingPlaceholder';

const GameStoryPage: React.FC = () => {
  const { gameId } = useParams<{ gameId: string }>();
  const [stories, setStories] = useState<GameStoryListItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    const fetchStories = async () => {
      try {
        const storiesData = await getGameStories(Number(gameId));
        setStories(storiesData);
      } catch (error) {
        console.error('ストーリーの取得に失敗しました', error);
        setStories([]);
      } finally {
        setLoading(false);
        setMounted(true);
      }
    };

    fetchStories();
  }, [gameId]);

  if (loading) {
    return <StoryLoadingPlaceholder type="list" />;
  }

  if (!Array.isArray(stories)) {
    console.error('ストーリーデータが配列ではありません:', stories);
    return (
      <Box>
        <Typography color="error">
          データの取得に失敗しました。
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ px: { xs: 2, sm: 3 }, py: { xs: 3, sm: 4 } }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 4 
      }}>
        <Typography 
          variant="h4" 
          sx={{ 
            fontWeight: 'bold',
            fontSize: { xs: '1.5rem', sm: '2rem' }
          }}
        >
          ゲームストーリー
        </Typography>
        <Button
          component={RouterLink}
          to={`/game/${gameId}/stories/create`}
          variant="contained"
          color="primary"
          sx={{
            borderRadius: '12px',
            textTransform: 'none',
            px: 3
          }}
        >
          新規作成
        </Button>
      </Box>

      {stories.length === 0 ? (
        <Typography>
          まだストーリーがありません。最初のストーリーを作成してみましょう。
        </Typography>
      ) : (
        <Grid container spacing={3}>
          {stories.map((story, index) => (
            <Grid item xs={12} sm={6} md={4} key={story.id}>
              <Box
                sx={{
                  opacity: mounted ? 1 : 0,
                  transform: mounted ? 'translateY(0)' : 'translateY(20px)',
                  transition: `all 0.5s ease ${index * 0.1}s`,
                }}
              >
                <StoryCard 
                  story={story}
                  to={`/game/${gameId}/stories/${story.id}`}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default GameStoryPage;