import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getUserProfileByUsername } from '../services/api';
import ProfilePage from './ProfilePage';
import { UserProfile } from '../types/UserProfile';
import { Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { SITE_NAME } from '../constants/site';

const PublicProfilePage: React.FC = () => {
  const { username } = useParams<{ username: string }>();
  const [profile, setProfile] = useState<UserProfile | undefined>(undefined);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchProfile = async () => {
      if (username) {
        try {
          const data = await getUserProfileByUsername(username);
          setProfile(data);
        } catch (error) {
          console.error('プロフィールの取得に失敗しました', error);
          setError('プロフィールの取得に失敗しました。');
        }
      }
    };

    fetchProfile();
  }, [username]);

  if (error) {
    return (
      <>
        <Helmet>
          <title>プロフィールが見つかりません | {SITE_NAME}</title>
        </Helmet>
        <Typography color="error">{error}</Typography>
      </>
    );
  }

  return <ProfilePage profile={profile} isPublic={true} />;
};

export default PublicProfilePage;
