import api from './api';

export const getCurrentUserId = async () => {
  try {
    const response = await api.get('/user/id');
    return response.data.id;
  } catch (error) {
    console.error('Failed to get current user ID:', error);
    return null;
  }
};
