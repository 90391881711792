import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { GameStoryListItem } from '../../types/content';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '24px',
  overflow: 'hidden',
  backgroundColor: 'transparent',
  boxShadow: '0 8px 32px rgba(0,0,0,0.12)',
  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
  position: 'relative',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 12px 48px rgba(0,0,0,0.18)',
  }
}));

const StyledCardMedia = styled(CardMedia)({
  position: 'relative',
  paddingTop: '75%',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%)',
    opacity: 0.9,
    transition: 'opacity 0.2s ease',
  }
});

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  padding: theme.spacing(2),
  background: 'transparent',
  color: '#fff',
  zIndex: 1,
}));

interface StoryCardProps {
  story: GameStoryListItem;
  to: string;
}

export const StoryCard: React.FC<StoryCardProps> = ({ story, to }) => {
  return (
    <Link to={to} style={{ textDecoration: 'none' }}>
      <StyledCard>
        <StyledCardMedia
          sx={{ height: 0 }}
          image={story.thumbnail || '/default-thumbnail.jpg'}
          title={story.title}
        />
        <StyledCardContent>
          <Typography 
            variant="h6" 
            sx={{ 
              fontWeight: 'bold',
              fontSize: '1.1rem',
              color: 'white',
              textShadow: '0 2px 4px rgba(0,0,0,0.3)'
            }}
          >
            {story.title}
          </Typography>
        </StyledCardContent>
      </StyledCard>
    </Link>
  );
};