import React from 'react';
import { Box, Typography } from '@mui/material';

interface SectionTitleProps {
  icon: React.ReactNode;
  text: string;
}

export const SectionTitle: React.FC<SectionTitleProps> = ({ icon, text }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, mt: 4 }}>
    {React.cloneElement(icon as React.ReactElement, { color: 'primary', sx: { mr: 1 } })}
    <Typography variant="h5" component="h2" color="white">
      {text}
    </Typography>
  </Box>
);