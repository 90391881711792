import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { GameStoryListItem } from '../../../types/content';
import { API_BASE_URL } from '../../../services/api';
import axios from 'axios';
import { StoryCard } from '../../../components/story/StoryCard';
import StoryLoadingPlaceholder from '../../../components/story/StoryLoadingPlaceholder';

const AllGameStoriesPage: React.FC = () => {
  const [stories, setStories] = useState<GameStoryListItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    const fetchAllStories = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/stories/all_stories/`);
        setStories(response.data);
      } catch (error) {
        console.error("全ストーリーの取得に失敗しました:", error);
      } finally {
        setLoading(false);
        setMounted(true);
      }
    };

    fetchAllStories();
  }, []);

  if (loading) {
    return <StoryLoadingPlaceholder type="list" />;
  }

  return (
    <Box sx={{ px: { xs: 2, sm: 3 }, py: { xs: 3, sm: 4 } }}>
      <Typography 
        variant="h4" 
        sx={{ 
          fontWeight: 'bold',
          fontSize: { xs: '1.5rem', sm: '2rem' },
          mb: 4
        }}
      >
        すべてのストーリー
      </Typography>

      {stories.length === 0 ? (
        <Typography>
          まだストーリーがありません。
        </Typography>
      ) : (
        <Grid container spacing={3}>
          {stories.map((story, index) => (
            <Grid item xs={12} sm={6} md={4} key={story.id}>
              <Box
                sx={{
                  opacity: mounted ? 1 : 0,
                  transform: mounted ? 'translateY(0)' : 'translateY(20px)',
                  transition: `all 0.5s ease ${index * 0.1}s`,
                }}
              >
                <StoryCard 
                  story={story}
                  to={`/game/${story.game}/stories/${story.id}`}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default AllGameStoriesPage;
