import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Typography, Grid, Pagination } from '@mui/material';
import { getGamesByPlatform, getGamesByGenre, getGamesByFranchise, getGamesByCollection, getGamesByGameEngine, getGamesByTheme, getGamesByCompany, getBasicGameStatuses } from '../../services/api';
import { Game } from '../../types/Game';
import LoadingPlaceholder from '../../components/LoadingPlaceholder';
import GameCard from '../../components/GameCard';
import { Helmet } from 'react-helmet-async';

const GAMES_PER_PAGE = 30;

const GameListByCategory: React.FC = () => {
  const { type, id } = useParams<{ type: string; id: string }>();
  const [games, setGames] = useState<Game[]>([]);
  const [loading, setLoading] = useState(true);
  const [categoryName, setCategoryName] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const [gameStatuses, setGameStatuses] = useState<{ [key: number]: number | null }>({});
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);
  }, []);

  // ゲームデータ取得後にステータスを一括取得
  useEffect(() => {
    const fetchGameStatuses = async () => {
      if (!isLoggedIn || games.length === 0) return;

      const gameIds = games.map(game => game.game_id);
      const statuses = await getBasicGameStatuses(gameIds);
      setGameStatuses(statuses);
    };

    fetchGameStatuses();
  }, [games, isLoggedIn]);

  // URLからページ番号を取得
  const queryParams = new URLSearchParams(location.search);
  const currentPage = parseInt(queryParams.get('page') || '1', 10);

  useEffect(() => {
    const fetchGames = async () => {
      setLoading(true);
      try {
        if (!id) {
          throw new Error('Invalid ID');
        }
        let response;
        switch (type) {
          case 'platform':
            response = await getGamesByPlatform(id, currentPage);
            setCategoryName(response.results.platform_name);
            setGames(response.results.games);
            break;
          case 'genre':
            response = await getGamesByGenre(id, currentPage);
            setCategoryName(response.results.genre_name);
            setGames(response.results.games);
            break;
          case 'franchise':
            response = await getGamesByFranchise(id, currentPage);
            setCategoryName(response.results.franchise_name);
            setGames(response.results.games);
            break;
          case 'collection':
            response = await getGamesByCollection(id, currentPage);
            setCategoryName(response.results.collection_name);
            setGames(response.results.games);
            break;
          case 'game-engine':
            response = await getGamesByGameEngine(id, currentPage);
            setCategoryName(response.results.game_engine_name);
            setGames(response.results.games);
            break;
          case 'theme':
            response = await getGamesByTheme(id, currentPage);
            setCategoryName(response.results.theme_name);
            setGames(response.results.games);
            break;
          case 'company':
            response = await getGamesByCompany(id, currentPage);
            setCategoryName(response.results.company_name);
            setGames(response.results.games);
            break;
          default:
            throw new Error('Invalid category type');
        }
        setTotalPages(Math.ceil(response.count / GAMES_PER_PAGE));
      } catch (error) {
        console.error('ゲーム一覧の取得に失敗しました', error);
        setGames([]);
      } finally {
        setLoading(false);
      }
    };

    fetchGames();
  }, [id, type, currentPage]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    navigate(`/games/${type}/${id}?page=${value}`);
  };

  return (
    <>
      <Helmet>
        <title>{`${categoryName} のゲーム一覧 | PULSE データベース`}</title>
      </Helmet>
      <Typography variant="h5" component="h1" gutterBottom>
        {type === 'platform' ? 'プラットフォーム' : 
         type === 'genre' ? 'ジャンル' : 
         type === 'franchise' ? 'IP' : 
         type === 'collection' ? 'シリーズ' : 
         type === 'game-engine' ? 'ゲームエンジン' : 
         type === 'theme' ? 'テーマ' :
         type === 'company' ? '会社' : 'カテゴリー'}: {categoryName}
      </Typography>
      {loading ? (
        <LoadingPlaceholder type="card" count={GAMES_PER_PAGE} />
      ) : (
        <>
          <Grid container spacing={1}>
            {games.map((game) => (
              <Grid item xs={6} sm={4} md={2.4} key={game.game_id}>
                <GameCard 
                  game={game} 
                  initialStatus={gameStatuses[game.game_id]}
                />
              </Grid>
            ))}
          </Grid>
          {totalPages > 1 && (
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}
            />
          )}
        </>
      )}
    </>
  );
};

export default GameListByCategory;