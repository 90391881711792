import React, { useState, useEffect } from 'react';
import { Container, Typography, Grid, Card, CardContent, CardActionArea } from '@mui/material';
import { Link } from 'react-router-dom';
import { getGenres } from '../../services/api';
import LoadingPlaceholder from '../../components/LoadingPlaceholder';

interface Genre {
  genre_id: number;
  name: string;
  slug: string;
}

const GenreListPage: React.FC = () => {
  const [genres, setGenres] = useState<Genre[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchGenres = async () => {
      try {
        const data = await getGenres();
        setGenres(data);
      } catch (error) {
        console.error('ジャンル一覧の取得に失敗しました', error);
      } finally {
        setLoading(false);
      }
    };

    fetchGenres();
  }, []);

  if (loading) {
    return <LoadingPlaceholder type="card" count={12} />;
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        ゲームジャンル一覧
      </Typography>
      <Grid container spacing={3}>
        {genres.map((genre) => (
          <Grid item xs={12} sm={6} md={4} key={genre.genre_id}>
            <Card>
              <CardActionArea component={Link} to={`/games/genre/${genre.genre_id}`}>
                <CardContent>
                  <Typography variant="h6" component="div">
                    {genre.name}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default GenreListPage;