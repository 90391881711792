import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, TextField, Button, Divider } from '@mui/material';
import { createGameStory } from '../../../services/contents_api';
import { GameStory, ContentBlock } from '../../../types/content';
import StoryEditor from '../../../components/story/StoryEditor';

const CreateGameStoryPage: React.FC = () => {
  const { gameId } = useParams<{ gameId: string }>();
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [heroImageUrl, setHeroImageUrl] = useState('');
  const [thumbnailUrl, setThumbnailUrl] = useState('');
  const [blocks, setBlocks] = useState<ContentBlock[]>([
    { type: 'text', content: '' }
  ]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const storyData: Partial<GameStory> = {
        game: Number(gameId),
        title,
        hero_image_url: heroImageUrl,
        thumbnail_url: thumbnailUrl,
        content: { blocks }
      };
      await createGameStory(storyData);
      navigate(`/game/${gameId}/stories`);
    } catch (error) {
      console.error('ストーリーの作成に失敗しました', error);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 800, mx: 'auto' }}>
      <Typography variant="h4" gutterBottom>新規ストーリー作成</Typography>
      
      <TextField
        fullWidth
        label="タイトル"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        margin="normal"
        required
      />

      <TextField
        fullWidth
        label="ヒーロー画像URL"
        value={heroImageUrl}
        onChange={(e) => setHeroImageUrl(e.target.value)}
        margin="normal"
        required
      />

      <TextField
        fullWidth
        label="サムネイル画像URL"
        value={thumbnailUrl}
        onChange={(e) => setThumbnailUrl(e.target.value)}
        margin="normal"
        required
      />

      <Divider sx={{ my: 3 }} />
      
      <Typography variant="h6" gutterBottom>
        ストーリー本文
      </Typography>

      <StoryEditor blocks={blocks} onChange={setBlocks} />

      <Box sx={{ mt: 3 }}>
        <Button type="submit" variant="contained" color="primary">
          作成
        </Button>
      </Box>
    </Box>
  );
};

export default CreateGameStoryPage;