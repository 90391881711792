import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, Tooltip } from '@mui/material';
import { UserProfile } from '../../types/UserProfile';

interface AccountSettingsFormProps {
  profile: UserProfile;
  onSave: (updatedProfile: UserProfile) => Promise<void>;
}

const AccountSettingsForm: React.FC<AccountSettingsFormProps> = ({ profile, onSave }) => {
  const [formData, setFormData] = useState(profile);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [canChangeUsername, setCanChangeUsername] = useState(false);

  useEffect(() => {
    setFormData(profile);
    checkUsernameChangeEligibility();
  }, [profile]);

  const checkUsernameChangeEligibility = () => {
    if (!profile.last_id_change_date) {
      setCanChangeUsername(true);
      return;
    }

    const lastChangeDate = new Date(profile.last_id_change_date);
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

    setCanChangeUsername(lastChangeDate < oneMonthAgo);
  };

  const validateUsername = (username: string) => {
    const regex = /^[a-zA-Z0-9_]{3,20}$/;
    if (!regex.test(username)) {
      return 'ユーザーIDは3〜20文字の英数字とアンダースコアのみ使用できます。';
    }
    return '';
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === 'username') {
      const error = validateUsername(value);
      setErrors({ ...errors, username: error });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!canChangeUsername) return;

    const usernameError = validateUsername(formData.username);
    if (usernameError) {
      setErrors({ ...errors, username: usernameError });
      return;
    }
    try {
      await onSave(formData);
    } catch (error: any) {
      if (error.response && error.response.data) {
        const serverErrors = error.response.data;
        const newErrors: { [key: string]: string } = {};
        Object.keys(serverErrors).forEach(key => {
          newErrors[key] = serverErrors[key][0];
        });
        setErrors(newErrors);
      }
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%', maxWidth: 400 }}>
      <Typography variant="h6" gutterBottom>
        アカウント設定
      </Typography>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        ユーザーIDは1ヶ月に1回のみ変更可能です。
      </Typography>
      <Tooltip title={canChangeUsername ? '' : 'ユーザーIDは1ヶ月に1回のみ変更可能です。'}>
        <TextField
          fullWidth
          margin="normal"
          label="ユーザーID"
          name="username"
          value={formData.username}
          onChange={handleChange}
          error={!!errors.username}
          helperText={errors.username}
          disabled={!canChangeUsername}
        />
      </Tooltip>
      {profile.last_id_change_date && (
        <Typography variant="body2" color="textSecondary" gutterBottom>
          前回の変更日: {new Date(profile.last_id_change_date).toLocaleDateString()}
        </Typography>
      )}
      <Button 
        type="submit" 
        variant="contained" 
        color="primary" 
        sx={{ mt: 2 }}
        disabled={!canChangeUsername}
      >
        保存
      </Button>
      {Object.keys(errors).map((key) => (
        <Typography key={key} color="error" sx={{ mt: 2 }}>
          {errors[key]}
        </Typography>
      ))}
    </Box>
  );
};

export default AccountSettingsForm;