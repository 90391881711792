import React from 'react';
import { Box } from '@mui/material';
import ReviewItem from './ReviewItem';
import { Review } from '../../types/Review';

interface ReviewListProps {
  reviews: Review[];
}

const ReviewList: React.FC<ReviewListProps> = ({ reviews }) => {
  return (
    <Box>
      {reviews.map((review) => (
        <ReviewItem key={review.id} review={review} />
      ))}
    </Box>
  );
};

export default ReviewList;