import React from 'react';
import { Box, Typography, Button, Grid, useTheme, Skeleton } from '@mui/material';
import { Link } from 'react-router-dom';
import TimelineIcon from '@mui/icons-material/Timeline';
import { SectionTitle } from './common/SectionTitle';

interface YearlyGamesSectionProps {
  loading?: boolean;
}

const YearlyGamesSection: React.FC<YearlyGamesSectionProps> = ({ loading = false }) => {
  const theme = useTheme();
  const currentYear = new Date().getFullYear();
  const years = Array.from(
    { length: currentYear - 1989 }, 
    (_, i) => currentYear - i
  );

  if (loading) {
    return (
      <Box sx={{ mt: 6, mb: 4 }}>
        <SectionTitle icon={<TimelineIcon />} text="年代別の人気ゲーム（世界）" />
        <Box 
          sx={{ 
            p: 2,
            borderRadius: 1,
            backgroundColor: `${theme.palette.primary.main}08`,
          }}
        >
          <Grid container spacing={1}>
            {Array.from({ length: 40 }).map((_, index) => (
              <Grid item xs={3} sm={2} md={1.5} key={index}>
                <Skeleton
                  variant="rectangular"
                  height={40}
                  sx={{
                    borderRadius: 0.5,
                    backgroundColor: `${theme.palette.primary.main}11`,
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 6, mb: 4 }}>
      <SectionTitle icon={<TimelineIcon />} text="年代別の人気ゲーム（世界）" />
      <Box 
        sx={{ 
          p: 2,
          borderRadius: 1,
          backgroundColor: `${theme.palette.primary.main}08`,
        }}
      >
        <Grid container spacing={1}>
          {years.map((year) => (
            <Grid item xs={3} sm={2} md={1.5} key={year}>
              <Button
                component={Link}
                to={`/popular-games/${year}`}
                fullWidth
                size="small"
                sx={{
                  color: 'white',
                  fontSize: '0.85rem',
                  backgroundColor: `${theme.palette.primary.main}11`,
                  border: `1px solid ${theme.palette.primary.main}22`,
                  py: 0.5,
                  '&:hover': {
                    backgroundColor: `${theme.palette.primary.main}22`,
                    borderColor: theme.palette.primary.main,
                  },
                }}
              >
                {year}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default YearlyGamesSection;